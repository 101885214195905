/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import LibraryTrack from "./LibraryTrack";
import Post from "./Post";
import Project from "./Project";


type Style = "success" | "error" | "info"; // popup type
type Layout = "vertical" | "horizontal" | "table"; // user cards
type ElementLayout = "card" | "table";
type ProfileLayout = "inline" | "card";
type Privacy = "private" | "unlisted" | "public" | "featured";
type Content = "featured_projects" | "user_projects" | "self_projects" | "all_projects" | "all_reports" | "library" 
                                   | "admin_projects" | "hidden_posts";
type Title = "Followers" | "Following" | "";
type Sort = "modified" | "modified_desc" | "created" | "created_desc" | 
            "num_flags" | "num_flags_desc" | "num_favorites" | "num_favorites_desc";
type Size = "small" | "medium" | "large";
type UIColor = "default" | "secondary" | "danger" | "confirm" | "danger-secondary";
type Reaction = "love" | "goat" | "poop" | "dance" | "fire" | "clap";
type FeedElement = Project | Post | LibraryTrack;

interface ReactionAPI {
  react : Reaction;
  user_ids? : Array<string>;
}

interface ReactConfig {
  icon : string;
  color : string;
}

type ReactionsConfig = {
  [key in Reaction]: ReactConfig;
};

let reactions : ReactionsConfig = { 
    "love" :  {"icon" : "❤", "color" : "#ff000080"},
    "clap" :  {"icon" : "👏", "color" : "yellow"},
    "fire" :  {"icon" : "🔥", "color" : "orange"},
    "dance" : {"icon" : "💃", "color" : "#ff000078"},
    "goat" :  {"icon" : "🐐", "color" : "grey"},
    "poop" :  {"icon" : "💩", "color" : "#a52a2a87"} 
  } 


export type { Style, Layout, ElementLayout, Content, Privacy, Sort, Title, ProfileLayout, Size, UIColor, Reaction, ReactionAPI,
              FeedElement }
export {reactions};