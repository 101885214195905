/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from "react";
import { FaStar } from "react-icons/fa";
import UserElement from "../User";
import ProjectElement from "../Project";
import PostElement from "../Post";
import Feed from "../Feed";
import ErrorMessage from "../primatives/ErrorMessage";

import Post from "../../models/Post";
import User from "../../models/User";
import Project from "../../models/Project";
import { Content, FeedElement } from "../../models/types";

import * as API from "../../api";

import "../../styles/shared.css";
import "../../styles/users.css";


interface IProps {
  currentUser? : User;
}

interface IState {
  user? : User;
  error : boolean;
  tab : string;
  elements : Array<FeedElement>
}

export default class ProfilePage extends React.Component<IProps, IState> {
  feed_end : boolean;
  feed_loading : boolean;
  feed_page : number;
  feed_fetch : Function;

  constructor(props : IProps) {
      super(props)

      this.state = {
        user : undefined,
        error : false,
        tab : "posts",
        
        elements : []
      };

      this.feed_end = false;
      this.feed_loading = false;
      this.feed_page = 0;
      this.feed_fetch = this.fetchPosts;
  }

  componentDidMount = async () => {
    if (window.location.pathname.replace("/user/", "") === this.props.currentUser?.uid) {
      window.history.pushState("", "TunePad Fabric", "/profile");
    }

    let user : User;

    if (window.location.pathname === "/profile") {
      user = this.props.currentUser!;
      document.title = "Profile - TunePad Community";
    } else {
      let uid : string = window.location.pathname.replace("/user/", "");
      let userResponse = await API.userDetails(uid);
      
      if (userResponse.status === 200) {
        let userData = await userResponse.json();
        user = new User(userData);
        await user.init();
        document.title = `${user.displayName} - TunePad Community`;
      } else { 
        this.setState({ error : true }); 
        return;
      }
    }

    this.setState({ user : user });
  }
  
  fetchPosts = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    let response = await API.getUserPosts(this.state.user!.uid!, this.feed_page);
    let data = await response.json();
    if (data.length === 0) this.feed_end = true;
    let new_posts = data.map((element : any) => { return new Post(element, this.props.currentUser?.uid) });

    this.feed_page += 1;
    this.feed_loading = false;

    this.setState({ elements : [...this.state.elements, ...new_posts]});
  }

  fetchProjects = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    let response = await API.getUserProjects(this.state.user!.username!, this.feed_page);
    let data = await response.json();
    if (data.length === 0) this.feed_end = true;
    let new_projects = data.map((element : any) => { return new Project(element) });

    this.feed_page += 1;
    this.feed_loading = false;
    
    this.setState({ elements : [...this.state.elements, ...new_projects]});
  }

  fetchFaves = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    let response = await API.getUserFaves(this.feed_page);
    let data = await response.json();
    if (data["posts"].length === 0) this.feed_end = true;
    let new_posts = data["posts"].map((element : any) => { return new Post(element, this.props.currentUser?.uid) });

    this.feed_page += 1;
    this.feed_loading = false;
    
    this.setState({ elements : [...this.state.elements, ...new_posts]});
  }

  switchTab = (tab : string) => {
    if (tab === "posts") this.feed_fetch = this.fetchPosts;
    else if (tab === "projects") this.feed_fetch = this.fetchProjects;
    else this.feed_fetch = this.fetchFaves;

    this.feed_end = false;
    this.feed_loading = false;
    this.feed_page = 0;

    this.setState({tab : tab, elements : []});
    this.feed_fetch();
  }

  render() { 
    if (this.state.error) return (<ErrorMessage/>);
    if (!this.props.currentUser || !this.state.user) return null; 

    let self : Content = "user_projects";
    if (this.state.user?.currentUser) self = "self_projects";

    let elements;
    if (this.state.tab === "projects") {
      elements = this.state.elements.map((item : any) => {
        return <ProjectElement key={item.url + Math.random()} project={item}
                               currentUser={this.props.currentUser}/>});
    } else {
      elements = this.state.elements.map((item : any) => {
        return <PostElement key={item.url + Math.random()} post={item}
                               currentUser={this.props.currentUser}/>});
    }

    return (
      <div id="profile-page">
        <div id="page-heading" className={`profile ${self}`}>
          <UserElement currentUser={this.props.currentUser} user={this.state.user!}/>
        </div>
        <div className="profile-content" id={self}>
          <section className="nav-container"> 
            <div className="discover-nav-alignment">
                <div className={`content-tabs ${this.state.tab}`}>
                  <div id="posts-nav" className="content-nav btn posts" 
                       onClick={() => this.switchTab("posts")}>
                    <div className="icon posts-section"></div>
                    <div className="content-nav-label">{(this.props.currentUser.uid === this.state.user.uid) ? "My " : ""} Posts</div>
                  </div>
                  <div id="projects-nav" className="content-nav btn projects" 
                       onClick={() => this.switchTab("projects")}>
                    <div className="icon guidebook-section"></div>
                    <div className="content-nav-label">{(this.props.currentUser.uid === this.state.user.uid) ? "My " : ""} Projects</div>
                  </div>
                  <div id="fave-nav" className={`content-nav btn faves ${(this.props.currentUser.uid === this.state.user.uid) ? "" : "hidden"}`} 
                       onClick={() => this.switchTab("faves")}>
                    <div className="icon"><FaStar id="fave-star"/></div>
                    <div className="content-nav-label">My Faves</div>
                  </div>
                </div>
              </div>
              <br/>
              <hr/>
            </section>
            <Feed currentUser={this.props.currentUser} loading={this.feed_loading} 
                  elements={elements} loadMoreElements={this.feed_fetch}/>
        </div>
      </div>)
  }
}