/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import * as API from '../api';

class LibraryTrack {
  name : string;
  description : string;
  id : number;

  beats : number;
  code : string;
  published : string;
  deleted : boolean;
  instrument : string;

  creation_date : string;

  author : string;
  author_id : string;

  approved : boolean;

  fave_count : number;

  audio_url : string;

  tags? : Array<string>;

  _data : any;

  constructor(data: any) {
    this._data = data;
    this.name = data['name'];
    this.description = data['description'];
    this.id = data['id'];
    this.beats = data['beats'];
    this.code = data['code'];
    this.instrument = data['instrument'];
    this.published = data['published'];
    this.deleted = data['deleted'];
    this.author = data['username'];
    this.author_id = data['user_id'];
    this.creation_date = data['created'];

    this.approved = data['approved'];
    
    this.fave_count = data['star_count'];
    this.audio_url = API.HOSTNAME + '/library/track/audio/' + data['id'];

    this.tags = data['tags'];

  }

  approve = async () => { 
    this._data['approved'] = true;
    this.approved = true;

    let response = await API.editLibraryTrack(this.id, this._data);

    return response.status;
  }

  saveEdit = async () => { 
    this._data['name'] = this.name;
    this._data['description'] = this.description;
    this._data['tags'] = this.tags;

    let response = await API.editLibraryTrack(this.id, this._data);

    return response.status;
  }

  delete = async () => {
    this._data['deleted'] = true;
    this.deleted = true;

    let response = await API.editLibraryTrack(this.id, this._data);

    return response.status;
  }
}

export default LibraryTrack;

