/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import * as API from '../api';
import Post from './Post';


class PostReport {
  post_id : number;
  author_username : string;
  author_id : string;
  flagged_by_username : string;
  flagged_by_id : string

  reason : string;
  details : string;

  flagged_timestamp : string;

  resolved : boolean;
  total_reports : number;

  post? : Post

  constructor(data: any) {

    this.post_id = data['post_id'];
    this.author_username = data['author_username'];
    this.author_id = data['author_id'];
    this.flagged_by_username = data['flagged_by_username'];
    this.flagged_by_id = data['flagged_by_user_id'];

    this.flagged_timestamp = data['flagged_timestamp'];
    this.total_reports = data['post_total_flags'] ?? 1;
  
    this.reason = data['flagged_reason']['type'] ?? 'no reason';
    this.details = data['flagged_reason']['long_description'];

    this.resolved = false;
  }

  setPost = async () => {
    try {
      let response = await API.getPost(this.post_id);
      
      if (response.status === 200) {
        let postData = await response.json();
        this.post = new Post(postData, this.author_id);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  ignoreReports = async () => { 
    let response = await API.ignoreAllFlags( this.post_id );
    return response.status;
  }

  hidePost = async () => { 
    let response = await API.ignorePost( this.post_id );
    return response.status;
  }

  deletePost = async () => {
    return -1;
  }
}

export default PostReport;

