/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import * as u from './utils';
import * as API from '../api';

class User {

  uid: string;

  /// logged in username string
  displayName?: string;
  username?: string;

  currentUser : boolean = false;
  role_id : number;

  email?: string;

  color?: string;
  icon?: string;

  /// photograph URL for non-anonymous user
  photoURL?: string;
  imageData? : any;
  created?: Date;
  isPublic : boolean;

  follower_ids: Array<string> = []; 
  following_ids: Array<string> = [];


  constructor(userData: any, currentUser?: boolean) {
    if (userData['id']) this.uid = userData['id'] ?? '';
    else if (userData['user_id']) this.uid = userData['user_id'] ?? '';
    else this.uid = '-1';

    this.displayName = userData['username'] ?? 'tunepad_user';
    this.username = userData['username'] ?? '';
    this.email = userData['email'] ?? '';
    this.photoURL = userData['photo'] ?? '';

    this.isPublic = userData['public'] ?? true;

    this.color = userData['color'] ?? this._randomColor();

    this.role_id = userData['role_id'] ?? 1;

    if (this.displayName?.includes('@')) this.displayName = this.displayName.split('@')[0];
    if (this.username === '') { this.username = 'tunepad_user_' +  this.uid?.slice(0, 4); }
    if (currentUser) this.currentUser = currentUser!;
  }

  async init() {
    if (this.currentUser) await this._setFollowing();
    await this._setFollowers();

    if ((this.isPublic || this.currentUser) && this.photoURL) {
      await this._setProfilePicture();
    }
  }

  async _setFollowers() {
    let response = await API.getFollowers(this.uid);
    let data = await response.json();
    this.follower_ids = data.follower_ids;
  }

  async toggleProfilePrivacy() {
    let response = await API.updatePrivacy(!this.isPublic);
    if (response.status) this.isPublic = !this.isPublic;
    return response.status;
  }

  async _setFollowing() {
    let response = await API.getFollowing();
    let data = await response.json();
    this.following_ids = data.following;
  }

  async _setProfilePicture() {
    try {
      let response = await API.retrievProfilePicture(this.photoURL!, this.uid!);
      let data = await response.json();

      this.imageData = data.img_bytes;    
    }
    catch (e) { }
  }

  async _followUser(user : User) {
    let uid = user.uid;
    if (!this.currentUser) return;

    let response = await API.follow(uid);

    if (response.status === 200) {
      this.following_ids.push(uid);
      user.follower_ids.push(this.uid);
    }
    return response.status;
  }

  async _unfollowUser(user : User) {
    let uid = user.uid;
    if (!this.currentUser) return;

    let response = await API.unfollow(uid);

    if (response.status === 200) {
      this.following_ids = this.following_ids.splice(this.following_ids.indexOf(uid), 1)
      this.follower_ids = this.following_ids.splice(this.follower_ids.indexOf(this.uid), 1)
      user.follower_ids.splice(user.follower_ids.indexOf(this.uid), 1)
    }
    return response.status;
  }

  isFollowing(user: User) { return this.following_ids.includes(user.uid); }

  async toggleFollow(user : User) {
    if (this.isFollowing(user)) return await this._unfollowUser(user);
    else return await this._followUser(user);
  }

  async changeEmail(new_email : string) {
    let response = await API.changeUserEmail(new_email);

    if (response.status === 200) this.email = new_email;
    return response.status;
  }

  async changePicture(file : File) {
    const formData  = new FormData();
    formData.append('file', file, file.name);
    formData.append('uid', this.uid);

    let response = await API.uploadFile(formData);

    return response.status;
   }


  _randomColor() {
    let r = u.getRandomInt(0, 128).toString(16);
    let g = u.getRandomInt(0, 128).toString(16);
    let b = u.getRandomInt(0, 128).toString(16);
    return `#${r}${g}${b}`;
  }
}

export default User;

