/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';

import '../../styles/shared.css';

import error_page from '../../resources/error_img.png';


export default class ErrorMessage extends React.Component<{}, {}> {
  render() {
    return (
      <div className='page-error'>
        <img src={error_page} alt='error'/>
        <h1>Page Not Found</h1>
        <p>
          Oops. The page or resource you are looking for doesn't exist. 
          Please <a href="mailto:tunepad@northwestern.edu">contact us</a> if you need help.
        </p>
      </div>);
    }
  }

  