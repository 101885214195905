/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaInbox } from 'react-icons/fa';

import UserElement from './User';

import User from '../models/User';
import { Layout, Title } from '../models/types';

import * as API from '../api';

import '../styles/shared.css';
import '../styles/users.css';



interface IProps {
  currentUser? : User;
  layout? : Layout;
  uids : Array<string>
  title? : Title;
}

interface IState {
  users : Array<User>
}

export default class UserList extends React.Component<IProps, IState> {
  public static defaultProps = {
    layout: "vertical",
    content : "self",
    title : ""
  };
  constructor(props : IProps) {
    super(props)
    this.state = {
      users : []
    };
  }

  async componentDidMount() {
    let users : Array<User> = [];
    for (const id of this.props.uids) {
      const data = await API.userDetails(id);
      
      const newUser = await data.json();
      users.push(new User(newUser));

    }
    this.setState({ users : users });
  }

  // prevent re-render if component has already loaded
  shouldComponentUpdate = (nextProps: Readonly<IProps>, nextState: Readonly<IState>) => {
    return this.state.users.length < 1;
  }

  render() { 
    if (!this.props.uids) return null;
    if (this.props.uids.length === 0) {
     return (
        <><h2>{this.props.title}</h2>
          <div className={`user-list-card ${this.props.layout}`}>
            <span id='empty-container'>
              <FaInbox id='empty-icon'/>
              <h2>There's nothing here yet</h2>
            </span>
          </div>
        </>)
    }

      return (
        <>
          <h2>{this.props.title}</h2>
          <div className={`user-list-card ${this.props.layout}`}>
            {this.state.users.map(item => {return <UserElement key={`item${Math.random()}`} currentUser={this.props.currentUser} user={item} layout='inline'/>})}
          </div>
        </>)
  }
}