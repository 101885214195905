/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

// adapted from tutorial by Phil Nash
// https://www.twilio.com/blog/audio-visualisation-web-audio-api--react

import React from 'react';
import '../styles/posts.css';


interface IProps {
  audioData : Uint8Array;
  bins : number;
  color : Array<number>;
}


export default class AudioVisualizer extends React.Component<IProps, {}> {
  canvas : React.RefObject<HTMLCanvasElement>;

  constructor(props : IProps) {
    super(props);
    this.canvas = React.createRef();
  }

  draw() {

    const { audioData } = this.props;
    const canvas : HTMLCanvasElement = this.canvas.current!;
    const height : number = document.getElementById('main-img')?.getBoundingClientRect().width!;
    const width : number = document.getElementById('main-img')?.getBoundingClientRect().height!;
    const context : CanvasRenderingContext2D = canvas.getContext('2d')!;
    
    canvas.width = width;
    canvas.height = height;
    context.strokeStyle = 'white';
    context.clearRect(0, 0, width, height);

    const bufferLength = this.props.bins - 7; // discard top few high freq bins
    const spacing = 2; // px

    const barWidth = ((width - (spacing * bufferLength)) / bufferLength);
    let x = 0;

    for (var i = 0; i < bufferLength; i++) {
      const barHeight = (audioData[i] / 255.0) * (height * (2/3)); // only draw in top 2/3s of canvas
      
      let l = this.scaleValue(1-(i/bufferLength), 0.0, 1.0, -30, -10);
      

      context.fillStyle = `hsl(${this.props.color[0]-10}, ${this.props.color[1]}%, ${this.props.color[2]-l}%)`;
      context.fillRect(x, height - barHeight, barWidth, barHeight);

      x += barWidth + spacing;
    }
    
  }

  scaleValue = (old_value : number, old_min : number, old_max : number, 
                                    new_min : number, new_max : number) => {
    return  ( (old_value - old_min) / (old_max - old_min) ) * (new_max - new_min) + new_min
};


  componentDidUpdate() {
    this.draw();
  }


  render() {    
    return <canvas className="visualizer" ref={this.canvas} 
                   width={document.getElementById('main-img')?.getBoundingClientRect().width}
                   height={document.getElementById('main-img')?.getBoundingClientRect().height}
                   />
  }
}

  