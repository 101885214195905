/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import Button from './Button';

import User from '../../models/User';

import '../../styles/shared.css';


interface IProps {
  currentUser? : User;
  action : Function;
}

interface IState {
  visible : boolean;
}

export default class Confirm extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props)

    this.state = {
      visible : false
    };
  }

  toggleVisible = () => {
    this.setState({ visible : !this.state.visible });
  }

  render() {
    let show_class = '';
    if (this.state.visible) show_class = 'show-content'
  
    return (
      <>
        <div className={`confirm-anchor ${show_class}`} onClick={this.toggleVisible}>{this.props.children}
          <div className='confirm-content'>
              Are you sure? 
              <span className='confirm-actions'>
                <Button color='danger' onClick={this.toggleVisible}>No</Button>
                <Button color='confirm' onClick={() => this.props.action()}>Yes</Button>
              </span>
          </div>
        </div>
      </>)
    }
  }

  