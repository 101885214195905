/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaAngleDown, FaAngleUp, FaTrash } from "react-icons/fa";
import moment from 'moment';
import PostElement from './Post';
import Button from './primatives/Button';
import { showPopup } from './primatives/Popup';

import User from '../models/User';
import PostReport from '../models/PostReport';

import '../styles/shared.css';
import '../styles/admin.css';


interface IProps {
  report : PostReport;
  currentUser? : User;
  refresh : Function;
}

interface IState {
  expanded : boolean;
}

export default class PostReportElement extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded : false
    };
  }

  toggleExpanded = async () => {
    if (!this.props.report.post) await this.props.report.setPost();
    this.setState({ expanded : !this.state.expanded});
  }

  hidePost = async () => {
    if (window.confirm("Are you sure you want to remove this post?")) {
      let status = await this.props.report.hidePost();

      if (status === 200) showPopup(`Post hidden!`, 'success', 5000);
      else showPopup(`Unable to hide post. Check console for more info.`, 'error', 5000);
      this.props.refresh();
    }
  }

  ignoreAllPostFlags = async () => {
    let status = await this.props.report.ignoreReports();

    if (status === 200) showPopup(`All post flags ignored!`, 'success', 5000);
    else showPopup(`Unable to resolve flags. Check console for more info.`, 'error', 5000);    
    this.props.refresh();
  }

  render() { 
    let post;
    if (this.props.report.resolved) return null;
    if (this.state.expanded && this.props.report.post) {
      post = <PostElement post={ this.props.report.post} currentUser={this.props.currentUser}/>
    }

    return (
      <>
        <div className={`library-item row`}>
          <span className='author'>
            <a href={`${window.location.origin}/user/${this.props.report.author_id}`} target="_blank" rel="noreferrer">{this.props.report.author_username}</a>
          </span>

          <span className='author'>
            <a href={`${window.location.origin}/user/${this.props.report.flagged_by_id}`} target="_blank" rel="noreferrer">{this.props.report.flagged_by_username}</a>
          </span>

          <span className='reason'>
            {this.props.report.reason}
          </span>

          <span className='actions'>
            <Button color="secondary" onClick={this.ignoreAllPostFlags}>
              <>Ignore {this.props.report.total_reports} Flag(s)</>
            </Button>
            <Button onClick={this.hidePost} color='danger'><><FaTrash/> &nbsp;Remove Post</></Button>
          </span>
          <span className='expand' onClick={this.toggleExpanded}>
            {this.state.expanded ? <FaAngleUp/> : <FaAngleDown/>}
          </span>
        </div>
        <div className={`details ${this.state.expanded ? 'expanded' : ''}`}>
          <span className='report-details'>
            <span className='row'><span><b>Flagged:</b> {moment(this.props.report.flagged_timestamp).format('MMMM Do YYYY, h:mm:ss a')}</span></span>
            <span className='row'><span><b>Details:</b> {this.props.report.details}</span></span>
            <hr/>
            
          </span>
          {post}
        </div>
      </>)
  }
}