/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';

import User from '../models/User';

import * as API from '../api';

import '../styles/shared.css';


interface IProps {
  currentUser? : User;
}

export default class TunePadHeader extends React.Component<IProps, any> {
  wrapperRef : React.RefObject<any>;

  constructor(props : IProps) {
    super(props)
    this.wrapperRef = React.createRef();

    this.state = {
      collapsed : false,
      admin_count: 0
    };
  }

  componentDidMount = async () => {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.props.currentUser?.role_id === 3) {
      let response = await API.adminNotificationCount();
      let counts = await response.json();

      let total = counts.flagged_posts_count + counts.hidden_posts_count + counts.library_tracks_queue_count; // + counts.library_project_queue_count 
      this.setState({admin_count : total});
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event : MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ collapsed : false });
    }
  }

  handleLogout = () => {
    try {
      API.logout()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          window.location.href = '/';
        }
        else {
          console.log("failed to logout");
        }
      })
    } catch (e) {
      console.log(e);
    } 
  }

  toggleMenu = () => {
    this.setState({ collapsed : !this.state.collapsed});
  }

  render() {
    if (window.location.pathname === '/') return <div/>

    const AUTHENTICATED = (this.props.currentUser !== null && this.props.currentUser !== undefined);
    let accountOptions = <><a className="btn join-link" href={`${API.LIVEDOMAIN}/join`}>Join</a>
                           <a className="btn login-link" href={`${API.LIVEDOMAIN}/login`}>Sign In</a></>
    if (AUTHENTICATED) { 
      accountOptions = (<><a className="btn profile-link" href="/profile">My Profile</a>
                          <span className="btn login-link" id='account' onClick={this.handleLogout}>Sign Out</span>
                        </>);
      if(window.location.pathname === '/profile') {
        accountOptions = (<><a className="btn profile-link active" href="/profile">My Profile</a>
                          <span className="btn login-link" id='account' onClick={this.handleLogout}>Sign Out</span>
                        </>);
      }
    }

    let discoverLink = <><a className="btn discover-link" href="/discover">Discover</a></>;
    let feedLink = <><a className="btn feed-link" href="/feed">My Feed</a></>;

    if(window.location.pathname === '/discover') {
      discoverLink = <><a className="btn discover-link active" href="/discover">Discover</a></>;
    }
    else if(window.location.pathname === '/feed'){
      feedLink = <><a className="btn feed-link active" href="/feed">My Feed</a></>;
    }

    let adminLink;
    if (this.props.currentUser?.role_id === 3 && window.location.pathname === '/admin') {
      adminLink = <><a className="btn admin-link active" href="/admin">Admin<div id='admin-count'>{this.state.admin_count}</div></a></>;  
    } else if (this.props.currentUser?.role_id === 3) {
      adminLink = <><a className="btn admin-link" href="/admin">Admin<div id='admin-count'>{this.state.admin_count}</div></a></>;  
    }

    return (
      <nav className="top-nav">
        <div className="top-nav-wrapper">
          <div className="top-nav-logo-wrapper">
            <a className="home-logo" href="/" aria-hidden="true">home</a>
            <a className="home-link" href="/"><b>TunePad</b>community</a><div id='beta'>beta</div>
          </div>
          <div className="expander"></div>
          <div className={`top-nav-logo-wrapper ${this.state.collapsed ? 'visible' : 'collapsed' }`}>
            <span className='menu-icon' onClick={this.toggleMenu}>&#9776;</span>
            <div className='menu-wrapper' ref={this.wrapperRef}>
              <><a className="btn discover-link" href={API.LIVEDOMAIN}>Home</a></>
              {adminLink}
              {feedLink}
              {discoverLink}
              {accountOptions}
            </div>
          </div>
        </div>
      </nav>);
    }
  }

  