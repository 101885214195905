/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import Twemoji from 'react-twemoji'; // standardize emoji display across platforms
import Post from '../../models/Post';
import { Reaction, reactions } from '../../models/types';
import User from '../../models/User';

import '../../styles/shared.css';


interface IProps {
  currentUser? : User;
  post : Post;
}


function ReactionElement(react : Reaction, count : number) {
  return (    
  <div className='tray-element' key={react}>
    <Twemoji options={{className: 'tray-icon'}} noWrapper><span className='icon-wrapper'>{reactions[react].icon}</span></Twemoji>
    <span className='tray-count'>{count}</span>
  </div>)
}


export default class ReactionsTray extends React.Component<IProps, {}> {  
  render() {    
    return (
      <div className='reactions-tray'>
        {this.props.post.post_reactions.map(item => {return ReactionElement(item.react, item.user_ids!.length)})}
      </div>)
    }
  }

  