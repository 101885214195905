/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaSave, FaUserCog } from "react-icons/fa";

import FollowButton from './FollowButton';
import UserList from './UserList';
import UserPicture from './primatives/UserPicture';
import Button from './primatives/Button';
import Switch  from './primatives/Switch';
import Confirm from './primatives/Confirm';
import Modal  from './primatives/Modal';
import Tooltip from './primatives/Tooltip';
import { showPopup } from './primatives/Popup';

import User from '../models/User';
import { isValidEmail } from '../models/utils';
import { ProfileLayout } from '../models/types';

import { LIVEDOMAIN } from '../api'; 

import '../styles/shared.css';
import '../styles/users.css';


interface IProps {
  currentUser? : User;
  user : User;
  layout : ProfileLayout;
}

interface IState {
  emailValue? : string; 
  following : boolean;
  changed : boolean;
  editing : boolean;
}

export default class UserElement extends React.Component<IProps, IState> {
  public static defaultProps = {
    layout: "card",
    following : false
  };

  constructor(props : IProps) {
      super(props)
      this.state = {
        emailValue: undefined,
        following: this.props.currentUser!.isFollowing(this.props.user),
        changed : false,
        editing: false
      };
  }

  toggleFollow = async () => {
    let result = await this.props.currentUser!.toggleFollow(this.props.user);

    if (result === 200) this.setState({following : !this.state.following});
    else showPopup(`Error ${result}: Unable to perform action.`, 'error', 5000);
  }


  submitEmail = async () => {
    if (this.state.emailValue && isValidEmail(this.state.emailValue)) {
      const result = await this.props.currentUser?.changeEmail(this.state.emailValue);

      if (result === 200) { showPopup('Email changed successfully', 'success', 5000); return true; }
      else { showPopup(`Error ${result}: Unable to change email`, 'error', 5000); return false; }
    }
    else { showPopup('Please enter a valid email', 'error', 5000); return false; }
  }

  renderCurrentUser() {
    return (
      <div className='user-card'>
        <UserPicture user={this.props.user} uid={this.props.user.uid} photoURI={this.props.user.photoURL} editable />

        <div className='user-info'>
          <div className='user-heading'><h2>{this.props.user.displayName}</h2> <br/>
            <Modal 
              className='followers-modal-anchor' 
              show={<div className='followers-modal-anchor'>{this.props.user.follower_ids.length ?? 1} Followers</div>}>
                <UserList title='Followers' uids={this.props.user.follower_ids} currentUser={this.props.currentUser}/>  
            </Modal>&nbsp;&nbsp;•&nbsp;&nbsp;
            <Modal 
              className='followers-modal-anchor' 
              show={<div className='followers-modal-anchor'>{this.props.user.following_ids.length ?? 1} Following</div>}>
                <UserList title='Following' uids={this.props.user.following_ids} currentUser={this.props.currentUser}/>  
            </Modal>
          </div>
          
          <div className={`user-edit-form ${this.state.editing ? '' : 'hidden'}`}>
            <label htmlFor='email' className='text-label'>Email</label>
            <div className="form-field-wrapper">
              <input id="email" name="email" tabIndex={2} defaultValue={this.props.user.email} 
                    onChange={e => { this.setState({ emailValue : e.target.value, changed: true }); }}/>
              <Confirm action={this.submitEmail}> 
                <Button disabled={!this.state.changed} ><FaSave/> &nbsp; <span id='save-text'>Save</span></Button>
              </Confirm>
              <div className="message hidden" id="email-message"/>
            </div>

            <hr/>

            <label htmlFor='privacy' className='text-label'>
              Profile Privacy
              <Tooltip text='Whether others can view your profile page, profile picture, and see your display name on posts.'/>
              </label>
            <div className="form-field-wrapper">
              <span id="privacy">
                <Switch value={this.props.user.isPublic} color='confirm' rounded
                        onChange={async () => {await this.props.user.toggleProfilePrivacy(); this.forceUpdate();}}
                        /> 
                &nbsp;&nbsp;PUBLIC
              </span>
            </div>

            <hr/>

            <div className="form-field-wrapper">
              <a href={`${LIVEDOMAIN}/account/reset`} id='reset-button' target='_blank' rel="noreferrer">
                <Button color='danger-secondary'>Password Reset</Button>
              </a>
            </div>
          </div>

          <Button onClick={() => this.setState({ editing : !this.state.editing})} >
              <span id='edit-profile'><FaUserCog/> &nbsp;{!this.state.editing ? 'Edit Settings' : 'Hide Settings'}</span>
          </Button>
        </div>
      </div>)
  }

  render() {
    if (!this.props.user) return null;

    // editable fields iff current user's profile page
    if (this.props.currentUser?.uid === this.props.user?.uid && this.props.layout !== 'inline') return this.renderCurrentUser();  
    
    if (this.props.layout === 'card') {
      return (
        <div className='user-card'>
            <UserPicture user={this.props.user} uid={this.props.user.uid} photoURI={this.props.user.photoURL}/>

            <div className='user-info'>
              <div className='user-heading'><h2>{this.props.user.displayName}</h2><br/>  
                <Modal 
                  className='followers-modal-anchor' 
                  show={<div className='followers-modal-anchor'>{this.props.user.follower_ids.length ?? 1} Followers</div>}>
                    <UserList uids={this.props.user.follower_ids} currentUser={this.props.currentUser} title='Followers'/>  
                </Modal>
              </div>
              <FollowButton user={this.props.user} currentUser={this.props.currentUser!} toggleFollow={this.toggleFollow} 
                            following={this.state.following}/>
            </div>
        </div>)
    }
    else {
      return (
      <span className='user-list-element'>
        <span className='user-name'>
          <UserPicture user={this.props.user} uid={this.props.user.uid} photoURI={this.props.user.photoURL} size='small'/>
          <a className='display-name' href={`/user/${this.props.user.uid}`}>{this.props.user.displayName}</a> 
        </span>
        <FollowButton user={this.props.user} currentUser={this.props.currentUser!} toggleFollow={this.toggleFollow} 
                      following={this.state.following}/>
      </span>)
    }
  }
}