/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaEdit, FaEllipsisV, FaFlag, FaShareAlt, FaTrash } from "react-icons/fa";
import Button from './primatives/Button';
import Modal from './primatives/Modal';
import { showPopup } from './primatives/Popup';

import Post from '../models/Post';
import User from '../models/User';

import '../styles/shared.css';
import '../styles/posts.css';


interface IProps {
  currentUser? : User;
  post : Post;
  deletePostHelper : Function;
  editPostHelper : Function;
}

interface IState {
  visible : boolean;
  reporting : boolean;
  reason : string;
  details : string;
}


export default class Options extends React.Component<IProps, IState> {
  error: string;
  
  constructor(props : IProps) {
    super(props)
    this.state = {
      visible : false,
      reporting : false,
      reason : '',
      details : ''
    };

    this.error = '';
  }

  toggleVisible = () => {
    this.setState({visible : !this.state.visible});
  }

  cancelReport = () => {
    this.setState({visible : false, reporting : false, reason : '', details : ''});
  }

  submitReport = async () => {
    if (this.state.reason === '' ) {
      this.error = 'error';
      this.forceUpdate();
      return;
    }

    let result = await this.props.post.flagPost(this.state.reason, this.state.details);
    if (result === 200) {
      showPopup(`Content reported.`, 'success', 5000);
      this.cancelReport();
      this.forceUpdate();
    }
    else {
      showPopup(`Unable to report content. Please try again.`, 'error', 5000);
      this.forceUpdate();
    }
  }

  share = () => {
    navigator.clipboard.writeText(`${window.location.origin}/post/${this.props.post.id}`);
    showPopup(`Link copied to clipboard.`, 'info', 5000);
  }

  render() {
    let drop = 'hidden';
    if (this.state.visible) drop = '';

    let delete_p;
    let edit_p;
    if (this.props.post.belongsToCurrent) {
      delete_p = <><hr/><span className='options-row delete' onClick={() => this.props.deletePostHelper()}>
      <FaTrash id='report-flag'/> Delete</span></>
      edit_p = <><hr/><span className='options-row' onClick={() => this.props.editPostHelper()}>
      <FaEdit id='report-flag'/> Edit</span></>
    }

    return (<div className='options' onMouseLeave={() => !this.state.reporting ? this.setState({visible : false}) : null}>
              <div>
                <FaEllipsisV onClick={this.toggleVisible} id='ellipsis-icon'/>
                
                <span className={`options-dropdown ${drop}`}>
                  <span className='options-row' onClick={this.share}>
                      <FaShareAlt id='report-flag'/> Share
                  </span>
                  <hr/>
                  <Modal onCancel={this.cancelReport} visibility={this.state.reporting}
                         show={<span className='options-row' onClick={() => this.setState({reporting : true})}
                                    ><FaFlag id='report-flag'/> Report</span>}
                  >
                    <h2>Report</h2>
                      <div className='report-body'>
                        <select name="" id="" placeholder="Reason"  
                                value={this.state.reason} onChange={e => { this.setState({ reason : e.target.value})}}
                                className={`${this.error}`}>
                          <option value=''>Choose a reason</option>
                          <option value='bullying'>Bullying or harassment</option>
                          <option value='language'>Harmful language</option>
                          <option value='spam'>Spam</option>
                          <option value='other'>Something else</option>
                        </select>
                        <textarea style={{resize: 'none'}} placeholder='Details...' maxLength={120} rows={3} 
                                  value={this.state.details} onChange={e => { this.setState({ details : e.target.value})}}/>
                        <Button onClick={this.submitReport}>Submit</Button>
                    </div>
                  </Modal>
                  {edit_p}
                  {delete_p}
                  
                </span>
                
              </div>

            </div>)
    }
  }

  