/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import PostElement from '../Post';
import ErrorMessage from '../primatives/ErrorMessage';

import User from '../../models/User';
import Post from '../../models/Post';

import * as API from '../../api';

import '../../styles/shared.css';
import '../../styles/posts.css';


interface IProps {
  currentUser? : User;
}

interface IState {
  post? : Post;
  error : boolean;
}

export default class SinglePostPage extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props)

    this.state = {
      error : false
    };
  }

  componentDidMount = async () => {
    let pid : number = parseInt(window.location.pathname.replace('/post/', ''));

    try {
      let response = await API.getPost(pid);
      
      if (response.status === 200) {
        let postData = await response.json();
        this.setState({ post : new Post(postData, this.props.currentUser?.uid)});
      }
      else { this.setState({ error : true }); }
    }
    catch (e) {
      console.log(e)
      this.setState({ error : true });
    }
  }


  render() { 
    if (this.state.error) return <ErrorMessage/>;
    if (!this.state.post) return null;
    else return (<div className='single-post-page'><PostElement post={this.state.post} currentUser={this.props.currentUser}/></div>);
  }
}