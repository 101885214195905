/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import moment from 'moment'; 

import '../../styles/shared.css';


interface IProps {
  time : string;
  modified : boolean;
}

export default class DateText extends React.Component<IProps, {}> { 
  render() {
    return (<div id='date-outer'>{moment.utc(this.props.time).local().fromNow()}
              <div id='date-inner'>{moment.utc(this.props.time).local().format('MMMM Do YYYY, h:mm a')}</div>
              {this.props.modified ? <span id='modified'> (edited)</span> : null}
            </div>);
      
  }

}
