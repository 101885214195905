/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import ReactDOM from 'react-dom';
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from "react-icons/fa";

import { Style } from '../../models/types';

import '../../styles/shared.css';


interface IProps {
  message : string;
  type : Style;
  life : number;
}

interface IState {
  visible : boolean;
}


export function showPopup(message : string, type : Style = 'info', ms : number = 5000) {
  try{
    ReactDOM.unmountComponentAtNode(document.getElementById('message-root')!);
    ReactDOM.render(<Popup message={message} type={type} life={ms}/>, document.getElementById('message-root')!);
  } catch (e) {
    console.debug('Message root node not mounted.');
  }
}


export default class Popup extends React.Component<IProps, IState> {
  public static defaultProps = {
    type: 'info'
  };

  constructor(props : IProps) {
    super(props)
    this.state = {
      visible: true
    };
  }

  componentDidMount = () => {
    this.setState({ visible : true });
    setTimeout(() =>{ 
      ReactDOM.unmountComponentAtNode(document.getElementById('message-root')!);
    },
    this.props.life)
  }

  render() {    
    if (!this.state.visible) {return <></>}

    let icon = <FaExclamationCircle id='icon info'/>
    if (this.props.type === 'success') { icon = <FaCheckCircle id='icon success'/>}
    else if (this.props.type === 'error') { icon = <FaTimesCircle id='icon error'/>}

    return (
      <>
        <div className={`popup ${this.props.type}`}>
          {icon}{this.props.message}
        </div>
      </>)
    }
  }

  