/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { UIColor } from '../../models/types';

import '../../styles/shared.css';


interface IProps {
  color : UIColor;
  rounded? : boolean;
  onClick? : Function;
  disabled : boolean
  additionalClasses? : string;
}


export default class Button extends React.Component<IProps, {}> {
  public static defaultProps = {
    color: 'default',
    corner: 'square',
    disabled: false
  };

  render() {    
    return (<button className={`${this.props.color} 
                                ${this.props.rounded ? 'rounded' : ''} 
                                ${this.props.additionalClasses ?? ''}`} 
                    onClick={this.props.onClick ? () => {this.props.onClick!()} : () => {}}
                    disabled={this.props.disabled}>
            {this.props.children}
            </button>)
    }
  }

  