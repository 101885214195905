/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

const assert = require("assert");

const REGEXP_EMAIL =
"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$";

function toBool(a: any, fallback? : boolean) {
  try{
    assert(a !== undefined && a !== null); 
    return Boolean(a).valueOf();
  }
  catch (e) {
    return fallback;
  }
}

function toString(a: any, fallback? : string) {
  try{
    assert(a !== undefined && a !== null); 
    return String(a).valueOf();
  }
  catch (e) {
    return fallback;
  }
}

function toNum(a: any, fallback? : number) {
  try{
    assert(a !== undefined && a !== null && !isNaN(a)); 
    return Number(a).valueOf();
  }
  catch (e) {
    return fallback;
  }
}

function getRandomInt(min : number = 0, max : number = 100) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

function isValidEmail(email : string) {
  let regexp = new RegExp(REGEXP_EMAIL),
  test = regexp.test(email);

  return test;
}

function stringToColour(str : string) {
  let hue : number = 0;
  for (var i = 0; i < str.length; i++) {
    hue += str.charCodeAt(i)
  }

  hue = 360 * (200 / (hue / str.length));

  return [hue, 75, 65]; // HSL format
}

function removeElement(arr : Array<any>, element : any) {
  var index = arr.indexOf(element);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

function removeElementByIndex(arr : Array<any>, index : number) {
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}



export { toBool, toString, toNum, getRandomInt, isValidEmail, stringToColour, removeElement, removeElementByIndex };