import loader_image from '../../resources/logo_headphones_animated.svg';

import '../../styles/shared.css';


export default function Loader()  {
    return (
      <div className="loading-wrapper">
        <div className="loading-dialog">
        <img id="tunepad-logo" src={loader_image} width="80" height="80" alt='loading...'></img>
          Loading...
        </div>
      </div>)

}