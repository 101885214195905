/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import AudioElement from './AudioElement';

import Project from '../models/Project';
import User from '../models/User';

import '../styles/shared.css';
import '../styles/projects.css';


interface IProps {
  project : Project;
  currentUser? : User;
}

export default class FeaturedProject extends React.Component<IProps, {}> {

  render() { 
    let audioIcon = <AudioElement audioURL={this.props.project.preview!} visualize={false}/>

    let artwork = <div className="project-icon" style={{backgroundColor : this.props.project.color}}>{audioIcon}</div>;
    if (this.props.project.artwork) {
      artwork = <div className="project-icon-art" style={{backgroundColor : this.props.project.color}}>
                  <img src={this.props.project.artwork} alt='featured art'/>{audioIcon}
                </div>
    }

    let overflowClass = ''
    if (this.props.project.name.length > 18) {
      overflowClass = 'overflowing'
    }

      return (
        <div className={`project-item featured card`}>
          <div className="project-summary">
            {artwork}
            <div className='project-info'>
              <a href={this.props.project.url} target="_blank" rel="noreferrer" className='project-name-link'>
                <div className={`project-name ${overflowClass}`}>{this.props.project.name}
                </div>
              </a>
              <div className="author">by <a href={`/user/${this.props.project.authorId}`}>{this.props.project.author}</a></div>
            </div>
          </div>

          <div className="project-details hidden">
            <div className="project-badge">
              <div className="left-column">
                <div className="artwork" style={{backgroundColor : this.props.project.color}}/>
              </div>
          </div>
        </div>
      </div>)
  }
}