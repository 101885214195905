/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { /*FaRegCommentDots,*/ FaCheck, FaSave, FaStar, FaTrash } from 'react-icons/fa';
import Options from './OptionsMenu';
import AudioElement from './AudioElement';
import Button from './primatives/Button';
import DateText from './primatives/DateText';
import ReactButton from './primatives/ReactButton';
import ReactionsTray from './primatives/ReactionsTray';
import UserPicture from './primatives/UserPicture';
import { showPopup } from './primatives/Popup';


import Post from '../models/Post';
import User from '../models/User';

import '../styles/shared.css';
import '../styles/posts.css';


interface IProps {
  post : Post;
  currentUser? : User;
  adminOptions? : boolean;
}

interface IState { 
  editing : boolean;
  editedContent : string;
}

export default class PostElement extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props)

    this.state = {
      editing : false,
      editedContent : this.props.post.content
    };
}

  toggledLiked = async () => {
    let result = await this.props.post.toggleLike();

    if (result === 200) { this.forceUpdate(); return true; }
    else { showPopup(`Unable to ${this.props.post.liked ? 'unlike' : 'like'} post. Please try again later.`, 'error', 5000); return false; }
  }

  sanitize = (input : string) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    for (const elm of doc.querySelectorAll('*') as any) {
      if ( ['IMG', 'SCRIPT', 'STYLE', 'AUDIO', 'VIDEO', 'SVG', 'CANVAS'].includes(elm.tagName) ) elm.remove();
      
      for (const attrib of elm.attributes) {
        if (attrib.name.startsWith('on')) elm.removeAttribute(attrib.name);
      }
    }
    return doc.body.innerHTML;
  };

  deletePostHelper = async () => {
    let status = await this.props.post.deletePost();

    if (status === 200) {
      this.forceUpdate();
      showPopup(`Post deleted successfully!`, 'success', 5000);
    }
    else {
      showPopup(`Unable to delete post. Please try again later.`, 'error', 5000);
    }
  }

  approvePost = async () => {
    let status = await this.props.post.approvePost();

    if (status === 200) showPopup(`Post approved successfully!`, 'success', 5000);
    else showPopup(`Unable to approve post. Please try again later.`, 'error', 5000);
    this.forceUpdate();
  } 

  ignorePost = async () => {
    if (window.confirm("Are you sure you want to remove this post?")) {
      let status = await this.props.post.ignorePost();

      if (status === 200) showPopup(`Post ignored successfully!`, 'success', 5000);
      else showPopup(`Unable to ignore post. Please try again later.`, 'error', 5000);
      this.forceUpdate();
    }
  } 

  editPost = () => {
    this.setState({editing : true})
  }

  cancelEdit = () => {
    this.setState({ editedContent : this.props.post.content, editing : false})
  }

  saveEdit = async () => {
    let status = await this.props.post.editPostContent(this.state.editedContent);

    if (status === 200) { showPopup(`Post edited successfully!`, 'success', 5000); this.setState({editing : false}); }
    else showPopup(`Unable to edit post. Please try again later.`, 'error', 5000);
    this.forceUpdate();
  }

  render() { 

    // TODO: reenable link search for meta tags once there's an API 
    let tags = <div/>
    if (this.props.post.project_meta_tags && this.props.post.project_meta_tags.length > 0) {
      tags =  <ul className='tags'>
                <div className='tags-scroll-container'>
                  {this.props.post.project_meta_tags?.map((t : any) => {return (
                    <span key={`${t}${Math.random()}`}>
                      <li style={{backgroundColor : this.props.post.color}}>{t}</li>
                    </span>
                      /*
                        <a href={`/discover?search=${t}`} key={`${t}${Math.random()}`}>
                          <li style={{backgroundColor : this.props.post.color}}>{t}</li>
                        </a>
                      */
                      )})}
                </div>
              </ul>
    }

    let author = <a href={`/user/${this.props.post.author_id}`}>{this.props.post.username}</a>;
    if (this.props.post.anonymous) author = <>{this.props.post.username}</>;

    
    let artwork = <div className={`post-icon`} id='main-img' style={{backgroundColor : this.props.post.color}}>
                   <AudioElement audioURL={this.props.post.preview!} visualize={true} color={this.props.post.hsl_color}/>
                  </div>;
    if (this.props.post.artwork) {
      artwork = <div className={`post-icon`} id='main-img' style={{backgroundImage : `url(${this.props.post.artwork})`}}>
                  {/*<img src={this.props.post.artwork} alt='post art'/>*/}
                  <AudioElement audioURL={this.props.post.preview!} visualize={true} color={this.props.post.hsl_color}/>
                </div>
    }

    let content : string = this.props.post.content ?? '';

    let adminActions;
    if (!this.props.post.approved && this.props.adminOptions) {
      adminActions = (
      <span className='admin-actions'>
        <button id='approve' onClick={this.approvePost}><FaCheck/> &nbsp; Approve</button>
        <button id='ignore' onClick={this.ignorePost}><FaTrash/> &nbsp; Remove</button>
      </span>);
    }

    try {
      let tagExp = new RegExp("(#)([A-Za-z0-9][A-Za-z0-9-_]*)", 'g');
      content = content.replaceAll(tagExp, (match) => {return `<a id='tag_text' href='/discover?search=${match.replace('#', '')}' style="font-weight: bold; color : ${this.props.post.color}">${match}</a>`});
      content = this.sanitize(content);
    } catch (e) { }

    let body = <div className='post-description' dangerouslySetInnerHTML={{__html : content}}/>
    if (this.state.editing) {
      body = 
        <div className='edit-area'>
          <textarea value={this.state.editedContent} maxLength={280} onChange={e => { this.setState({ editedContent : e.target.value }); }}/>
          <span id={'character-counter'}>{280 - this.state.editedContent.length} character(s) remaining</span>
          <span className='edit-actions'>
            <Button color='secondary' onClick={this.cancelEdit}>Cancel</Button>
            <Button color='default' disabled={this.state.editedContent === this.props.post.content} onClick={this.saveEdit}><><FaSave/>&nbsp; Save</></Button>
          </span>
        </div>;
      }
      
    return (
        <div className={`post-item ${ this.props.post.deleted ? 'deleted' : ''} ${ this.state.editing ? 'editing' : ''}`}>
          <span className='post-header'>
            <UserPicture photoURI={this.props.post.author_photoURI} uid={this.props.post.author_id} size='small'/>
            <div className='post-info'>Posted by&nbsp;{author}
                &nbsp;<DateText time={this.props.post.created} modified={this.props.post.isModified}/>
                <div className='project-link'>from&nbsp;<a href={this.props.post.project_link}>{this.props.post.project_name}</a></div>
            </div>
          </span>
         
          <Options post={this.props.post} deletePostHelper={this.deletePostHelper} editPostHelper={this.editPost}/>
          <div className='post-content'>
            {artwork}
            <div className='post-body'>
              {body}
            </div>
          </div>
          
          <div className='post-toolbar'>
            {tags}
            <span className={`button-group`}>
              <button className={`post-like-button ${this.props.post.liked ? 'liked' : ''}`} 
                      disabled={(this.props.currentUser?.uid === this.props.post.author_id)}
                      onClick={this.toggledLiked}>
                <FaStar className='btn-icon'/>&nbsp;&nbsp;
                <span id='post-like-count'>{this.props.post.likes!}</span>
            </button>
            {!(this.props.currentUser?.uid === this.props.post.author_id) ? 
                <ReactButton currentUser={this.props.currentUser!} post={this.props.post} 
                             refreshPost={() => this.forceUpdate()}/> : null}
            </span>
          </div>
          <ReactionsTray currentUser={this.props.currentUser!} post={this.props.post}/>
          {adminActions}
        </div>);
  }
}