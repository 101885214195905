/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from "react";
import { FaInbox } from "react-icons/fa";

import Loader from "./primatives/Loader";

import User from "../models/User";

import "../styles/shared.css";
import "../styles/feeds.css";


interface IProps {
  currentUser? : User;
  loadMoreElements : Function;
  elements : Array<React.ReactNode>;
  loading : boolean;
  header? : React.ReactNode;
  infiniteScroll? : "element" | "window" | "none";
  additionalClasses? : string;
}

export default class Feed extends React.Component<IProps, {}> {
  public static defaultProps = {
    infiniteScroll: "window",
  };

  componentDidMount() {
    if (this.props.infiniteScroll === "window") window.addEventListener('scroll', this.listenToWindowScroll);
    this.loadMoreElementsWrapper();
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToWindowScroll);
  }

  loadMoreElementsWrapper = async () => {
    if (this.props.loading) return;
    await this.props.loadMoreElements();
  }

  listenToElementScroll = () => {
    let element = document.getElementsByClassName('post-feed')[0];

    const winScroll =
      element.scrollTop || element.scrollTop;
  
    const height =
      element.scrollHeight -
      element.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled >= 0.999 || Number.isNaN(scrolled) ) {
      this.loadMoreElementsWrapper();
    }
  }

  listenToWindowScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

      if (scrolled >= 0.999 || Number.isNaN(scrolled) ) {
        this.loadMoreElementsWrapper();
      }
    }

  render(){ 
    if ((!this.props.elements || this.props.elements.length === 0) && !this.props.loading) {
      return (<div className={`post-feed ${this.props.additionalClasses}`}>
        {this.props.header}
        <span id="empty-container">
          <FaInbox id="empty-icon"/>
          <h2>There's nothing here yet</h2>
        </span>
        </div>);
    }
    let loader;
    if (this.props.loading) { loader = <Loader/>; }

    return (
      <div className={`post-feed ${this.props.additionalClasses ?? ''}`} 
           onScroll={(this.props.infiniteScroll === "element") ? this.listenToElementScroll : undefined}>
        {this.props.header}
        {this.props.elements}
        {loader}
      </div>);
  }
}