/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { UIColor } from '../../models/types';

import '../../styles/shared.css';



interface IProps {
  onChange : Function;
  value : boolean;
  label? : string;
  color : UIColor;
  rounded? : boolean;
  onClick? : Function;
  disabled : boolean
}


export default class Switch extends React.Component<IProps, {}> {
  public static defaultProps = {
    color: 'default',
    corner: 'square',
    disabled: false,
  };


  render() {    
    return (
      <>{this.props.label}
        <label className='switch'>
          <input type="checkbox" checked={this.props.value} onChange={ async () => await this.props.onChange() } disabled={this.props.disabled}/>
          <span className={`slider ${this.props.color} ${this.props.rounded ? 'round' : ''}`}></span>
        </label>
      </>
      )
    }
  }

  