/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import User from '../models/User';
import Button from './primatives/Button';

import '../styles/shared.css';


interface IProps {
  user : User;
  currentUser : User;
  toggleFollow : Function;
  following : boolean;
}


export default class FollowButton extends React.Component<IProps, {}> {
  render() { 
    if (this.props.currentUser.uid === this.props.user.uid) return null;

    if (!this.props.following) return (<Button color='confirm' onClick={() => this.props.toggleFollow()}>Follow</Button>);
    else return (<Button color='danger' onClick={() => this.props.toggleFollow()}>Unfollow</Button>);
  }
}