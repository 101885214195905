/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from "react";

import Feed from "../Feed";
import PostElement from "../Post";
import Library from "../Library";
import ReportTable from "../ReportTable";
import ProjectElement from "../Project";
import ErrorMessage from "../primatives/ErrorMessage";

import User from "../../models/User";
import Post from "../../models/Post";
import Project from "../../models/Project";
import { FeedElement } from "../../models/types";

import * as API from "../../api";

import "../../styles/shared.css";
import "../../styles/admin.css";


type AdminTab = "post_approval" | "reports" | "library" | "projects";

interface IProps {
  currentUser? : User;
}

interface IState {
  user? : User;
  error : boolean;
  posts : Array<Post>;
  tab : AdminTab;
  elements : Array<FeedElement>
}

export default class AdminPage extends React.Component<IProps, IState> {
  feed_end : boolean;
  feed_loading : boolean;
  feed_page : number;
  totalPosts : number;

  constructor(props : IProps) {
      super(props)

      this.state = {
        user : undefined,
        error : false,
        posts : [],
        tab : "post_approval",
        elements : []
      };

      this.feed_end = false;
      this.feed_loading = false;
      this.feed_page = 0;
      this.totalPosts = 0;
  }

  componentDidMount = async () => {
    let user : User = this.props.currentUser!;

    document.title = "Admin - TunePad Community";
    if (user.role_id !== 3) return this.setState({ error : true });
  }

  changeTab = (tab : AdminTab) => {
    this.feed_loading = this.feed_end = false;
    this.feed_page = 0;

    this.setState({tab : tab, elements : []});
  }

  fetchPostQueue = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    let response = await API.getHiddenPosts(this.feed_page); 
    let data = await response.json();
    if (data["posts"].length === 0) this.feed_end = true;
    let new_posts = data["posts"].map((element : any) => { return new Post(element) });
    this.totalPosts = data["num_posts"];

    this.feed_page += 1;
    this.feed_loading = false;

    this.setState({ elements : [...this.state.elements, ...new_posts]});
  }

  fetchProjects = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    if (this.state.tab === "projects" && this.state.elements.length === 0) return this.populateAdminProjects();
    let response = await API.getAllProjects(this.feed_page); 

    let data = await response.json();
    if (data.length === 0) this.feed_end = true;
    let new_projects = data.map((element : any) => { return new Project(element) });

    this.feed_page += 1;
    this.feed_loading = false;

    this.setState({ elements : [...this.state.elements, ...new_projects]});
  }

  populateAdminProjects = async () => {
    let featured_response = await API.getFeaturedProjects(0);
    let all_response = await API.getAllProjects(0); 

    let all_elements : Array<Project> = [];
    if (all_response.status === 200 && featured_response.status === 200) {
      let data1 = await featured_response.json();
      let data2 = await all_response.json()

      all_elements =  [...data1, ...data2].map((element : any) => { return new Project(element)});
      all_elements = all_elements.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )

      this.feed_page += 1;
      this.feed_loading = false;
      
      this.setState({ elements : [...this.state.elements, ...all_elements] });
    } 
  }

  render() { 
    if (!this.props.currentUser) return null; 
    if (this.state.error) return (<ErrorMessage/>);

    let content;
    if (this.state.tab === "post_approval") {
      let header = <span className='post-count'>Showing {this.totalPosts} posts</span>
      content = <Feed additionalClasses="admin-table-card post-approval" currentUser={this.props.currentUser} 
                      header={header} loading={this.feed_loading} loadMoreElements={this.fetchPostQueue} 
                      infiniteScroll={'element'} key='post_queue'
                      elements={this.state.elements.map((item : any) => {
                                return <PostElement key={item.url} post={item}
                                                    currentUser={this.props.currentUser} adminOptions/>})}/>
    } else if (this.state.tab === "reports") {
      content = <ReportTable currentUser={this.props.currentUser}/>

    } else if (this.state.tab === "library") {
      content = <Library currentUser={this.props.currentUser} admin/>
    } else {
      let header =          
        <div id="table-header" className="row" key="head">
          <span className="name">Name</span>
          <span className="author">Author</span>
          <span className="actions"> </span>
        </div>
      content = <Feed additionalClasses="admin-table-card project-container" currentUser={this.props.currentUser} 
                      loading={this.feed_loading} loadMoreElements={this.fetchProjects} header={header} 
                      infiniteScroll={'element'} key='project_list'
                      elements={this.state.elements.map((item : any) => {
                                return <ProjectElement key={item.long_id} project={item} layout={"table"}
                                                    currentUser={this.props.currentUser}/>})}/>
    }

    return (
      <div className="content admin">
        <span id="page-heading-title admin">
          <h1>Admin</h1>
          <div className="tabs">
            <span id="posts" className={this.state.tab === "post_approval" ? "selected" : ""} 
                             onClick={() => this.changeTab("post_approval")}>Post Queue</span>
            <span id="flags" className={this.state.tab === "reports" ? "selected" : ""} 
                             onClick={() => this.changeTab("reports")}>Flagged Posts</span>
            <span id="library" className={this.state.tab === "library" ? "selected" : ""} 
                               onClick={() => this.changeTab("library")}>Library</span>
            <span id="projects" className={this.state.tab === "projects" ? "selected" : ""}
                                onClick={() => this.changeTab("projects")}>Projects</span>
          </div>
        </span>
          {content}
        
     </div>)
  }
}