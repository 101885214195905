/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { MdAddReaction } from 'react-icons/md';
import Twemoji from 'react-twemoji'; // standardize emoji display across platforms
import Post from '../../models/Post';
import { Reaction, reactions } from '../../models/types';
import User from '../../models/User';

import '../../styles/shared.css';
import { showPopup } from './Popup';


interface IProps {
  currentUser? : User;
  post : Post;
  refreshPost : Function;
}

interface IState {
  selection? : string;
}


export default class ReactButton extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props)

    this.state = { selection : undefined };
  }

  componentDidMount = () => {
    this.setState({ selection : this.props.post.currentUserReaction(this.props.currentUser!.uid) });
  }

  react = async (reaction : Reaction) => {
    let response = await this.props.post.addReaction(this.props.currentUser!.uid, reaction);

    if (response === 200) this.setState({ selection : reaction });
    else showPopup(`Unable to react to post. Please try again later.`, 'error', 5000);
    this.props.refreshPost();
  }

  clearReaction = async () => {
    let response = await this.props.post.removeReaction(this.props.currentUser!.uid);

    if (response === 200) this.setState({ selection : undefined });
    else showPopup(`Unable to react to post. Please try again later.`, 'error', 5000);
    this.props.refreshPost();
  }
  
  render() {
    if (!this.props.currentUser) return null;

    let display = <MdAddReaction id='add-reaction'/>;
    let border = "";

    if (this.state.selection) {
      display = 
        <Twemoji options={{className: 'outer-icon'}}>
          {reactions[this.state.selection as keyof typeof reactions]["icon"]!}  
        </Twemoji>
      border = reactions[this.state.selection as keyof typeof reactions]["color"]!
    }

    return (
      <>
        <span className='tooltip-outer reactions'>
          <button className={`post-reaction-button`} onClick={this.clearReaction} style={{borderColor : border}}>
            {display}
          </button>

          <span className='tooltip-text'>
            <Twemoji options={{className: 'twemoji icon love'}} noWrapper>
              <span onClick={() => this.react('love')} className={this.state.selection === 'love' ? 'selected' : ''}>&#10084;</span>
            </Twemoji>
            <Twemoji options={{className: 'twemoji icon clap'}} noWrapper>
              <span onClick={() => this.react('clap')} className={this.state.selection === 'clap' ? 'selected' : ''}>&#128079;</span>
            </Twemoji>
            <Twemoji options={{className: 'twemoji icon fire'}} noWrapper>
              <span onClick={() => this.react('fire')} className={this.state.selection === 'fire' ? 'selected' : ''}>&#128293;</span>
            </Twemoji>
            <Twemoji options={{className: 'twemoji icon dance'}} noWrapper>
              <span onClick={() => this.react('dance')} className={this.state.selection === 'dance' ? 'selected' : ''}>&#128131;</span>
            </Twemoji>
            <Twemoji options={{className: 'twemoji icon goat'}} noWrapper>
              <span onClick={() => this.react('goat')} className={this.state.selection === 'goat' ? 'selected' : ''}>&#128016;</span>
            </Twemoji>
            <Twemoji options={{className: 'twemoji icon poop'}} noWrapper>
              <span onClick={() => this.react('poop')} className={this.state.selection === 'poop' ? 'selected' : ''}>&#128169;</span>
            </Twemoji>
          </span>
        </span>
      </>)
    }
  }

  