/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaAngleDown, FaSearch } from 'react-icons/fa';

import User from '../models/User';
import { Sort } from '../models/types';

import '../styles/shared.css';
import '../styles/feeds.css';


interface IProps {
  searchCallback : Function
  currentUser? : User;
}

interface IState {
  optionsHidden : boolean;
  searchInput : string;
  criteria : Sort;
}

export default class SearchMenu extends React.Component<IProps, IState>  {
  wrapperRef : any;
  setWrapperRef : any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      optionsHidden : true,
      criteria : 'created_desc',
      searchInput : ''
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event : MouseEvent) {
    if (!this.state.optionsHidden && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({ optionsHidden : true});
    }
  }

  updateSort = (criteria : Sort) => {
    this.props.searchCallback(this.state.searchInput, criteria)
    this.setState({criteria : criteria});
  }

  _handleKeyDown = (e : any) => {
    if (e.key === 'Enter') {
      this.props.searchCallback(this.state.searchInput, this.state.criteria)
    }
  }

  updateSearchValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: can also auto-populate list of results as user types

    this.setState({
      searchInput: evt.target.value
    });
  }

  render() {

    let searchText = 'Newest';
    if (this.state.criteria === 'created') searchText = 'Oldest';
    else if (this.state.criteria === 'num_favorites_desc') searchText = 'Most Favorites';
    
    return (
      <div className='search-menu'>
        <div className="search-container">
        <div id="search-box" className="input-wrapper">
          <FaSearch className="search-icon" onClick={() => this.props.searchCallback(this.state.searchInput, this.state.criteria)}/>
          <input id="tunepad-search" className="search-input" type="text" 
                 placeholder="search" value={this.state.searchInput} onChange={this.updateSearchValue}
                 onKeyDown={this._handleKeyDown}/>
        </div>
        <ul id="search-box-results" className="search-results" style={{"display": "none"}}></ul>
        </div>
        <div ref={this.wrapperRef} className="sort-menu">
          <span className={`sort-criteria ${this.state.criteria}`} onClick={() => {this.setState({ optionsHidden : !this.state.optionsHidden})}}>{searchText}</span> <FaAngleDown/>
          <ul className={`drop-menu ${this.state.criteria} ${this.state.optionsHidden ? 'hidden' : ''}`}>
            <li className="menu-item created_desc" onClick={() => this.updateSort('created_desc')}>Newest</li>
            <li className="menu-item created" onClick={() => this.updateSort('created')}>Oldest</li>
            <li className="menu-item num_favorites_desc" onClick={() => this.updateSort('num_favorites_desc')}>Most Favorites</li>
          </ul>
        </div>
    </div>);
  }
}
