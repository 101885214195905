/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import * as u from './utils';
import * as API from '../api';
import { LIVEDOMAIN } from '../api';
import { Reaction, ReactionAPI } from './types';



class Post {
  content: string = '';
  id: number;
  url: string;

  hsl_color: Array<number>;
  color : string;

  author_id?: string;
  author_photoURI? : string;
  username?: string;
  anonymous : boolean;

  artwork?: string; /// artwork URL
  preview?: string; /// audio preview URL

  tags: Array<string> = [];

  created: string;
  modified: string;
  isModified : boolean;
  likes?: number = 0;
  liked: boolean = false;

  project_link : string;
  project_metadata? : any;
  project_name : string;
  project_meta_tags : Array<string>;
  post_reactions : Array<ReactionAPI>;

  belongsToCurrent : boolean = false; // to decide to render delete/edit
  deleted : boolean = false;
  approved : boolean;
  ignored: boolean;


  constructor(data: any, current_uid? : string) {
    this.id = data['post_id'] ?? -1;

    this.hsl_color = u.stringToColour(data['posted_timestamp'] + data['content']);
    this.color = `hsl(${this.hsl_color[0]}, ${this.hsl_color[1]}%, ${this.hsl_color[2]}%)`;
    this.artwork = data['artwork'];
    this.author_id = data['user']['user_id'] ?? '';
    this.author_photoURI = data['user']['photo'] ?? '';
    this.username = data['user']['display_name'] ?? 'tunepad_user';
    this.anonymous = (data['user']['display_name'] === '');
    this.preview = data['project_preview'] ?? '';
    this.content = data['content'] ?? '';
    this.created = data['posted_timestamp'];
    this.modified = data['modified_timestamp'];
    this.isModified = this.getModified(); 
    this.url = `${LIVEDOMAIN}/post/${this.id}`

    this.approved = data['show'] ?? true;
    this.ignored = data['remove_from_queue'] ?? false;

    this.likes = data['num_favorites'] ?? 0;
    this.liked = data['fav_by_curr_user'] ?? false;
    this.post_reactions = typeof data['reactions'] === 'string' ? [] : data['reactions'];

    // this check is solely for backwards compatibility
    this.project_metadata = (Object.keys(data['project_metadata']).length !== 0) ? JSON.parse(data['project_metadata']) : '';
    this.project_name = this.project_metadata['name'] ?? 'TunePad Project';
    this.project_meta_tags = this.processMeta();
    this.project_link = `${API.LIVEDOMAIN}/project/${data['project_id']}`;


    if (current_uid && current_uid === data['user']['user_id']) this.belongsToCurrent = true;

    // removed parsing because i'm not using this field  
    /*try {
      let parsed_tags = JSON.parse(data['tags']);
      
      for (var key in parsed_tags){
        this.tags = this.tags.concat(parsed_tags[key]);
      }
    }
    catch (e) {
      console.log(e);
      for (var t_key in data['tags']) {
        this.tags = this.tags.concat(data['tags'][t_key].split(','));
      }
    }*/
    
    if (this.username?.includes('@')) { this.username = this.username.split('@')[0]; }
    else if (this.username === '') { this.username = 'tunepad_user_' +  this.author_id?.slice(0, 4); }
  }

  getModified = () => {
    return (new Date(this.modified).getTime() - new Date(this.created).getTime()) !== 0
  }

  processMeta = () : Array<string>  => {
    let project_metadata_tags : Array<string> = [];
    if (!this.project_metadata) return project_metadata_tags;
    
    project_metadata_tags.push(`${this.project_metadata['bpm']} bpm`);
    project_metadata_tags.push(`${this.project_metadata['loc']} lines`);
    project_metadata_tags = project_metadata_tags.concat(this.project_metadata['instrument-types']);

    return project_metadata_tags;
  }

  likePost = async () => {
    let response = await API.like(this.id);

    if (response.status === 200 || response.status === 201) {
      this.liked = true;
      this.likes! += 1;
    } 
    return response.status
  }

  unlikePost = async () => {
    let response = await API.unlike(this.id);

    if (response.status === 200) {
      this.liked = false;
      this.likes! -= 1;
    } 
    return response.status;
  }

  toggleLike = async () => {
    try {
      if (!this.liked) return await this.likePost();
      else return await this.unlikePost();

    } catch (e) { return 500; }
  }

  deletePost = async () => {
    if (this.belongsToCurrent && window.confirm('Are you sure you\'d like to delete this post?')) {

      let response = await API.deletePost(this.id);

      if (response.status === 200 || response.status === 201) this.deleted = true;
      return response.status;
    }
    return 404;
  }

  flagPost = async (reason : string, details : string) => {
    let response = await API.flagContent( this.id, reason, details );
    return response.status;
  }

  approvePost = async () => {
    let response = await API.approvePost( this.id );

    if (response.status === 200) this.deleted = true;
    return response.status;
  }

  ignorePost = async () => {
    let response = await API.ignorePost( this.id );

    if (response.status === 200) this.deleted = true;
    return response.status;
  }

  editPostContent = async (newContent : string) => {

    let tags = this.getParsedTags(newContent);
    let response = await API.editPostContent( this.id, newContent, tags );
    if (response.status === 200) {this.content = newContent; this.tags = tags; this.modified = new Date().toISOString()}
    return response.status;
  }

  getParsedTags(postContent : string) {
    let tagExp = new RegExp("(#)([A-Za-z0-9][A-Za-z0-9-_]*)", 'g');

    let matches = postContent.matchAll(tagExp);

    let tags : Array<string> = [];

    for (var match of matches) {
      tags.push(match[2]);
    }

    return tags;
  }

  currentUserReaction = (user_id : string) => {
    for (var react of this.post_reactions!) {
      if (react.user_ids?.includes(user_id)) return react.react;
    }

    return undefined;
  }

  addReaction = async (user_id : string, reaction : Reaction) => {
    if (this.currentUserReaction(user_id)) {
      for (var react of this.post_reactions!) {
        if (react.user_ids?.includes(user_id)) react.user_ids = react.user_ids.filter((item : string) => item !== user_id );
        if (react.user_ids?.length === 0) u.removeElement(this.post_reactions, react);
      }
    }

    let post_reaction = this.post_reactions!.find((item : ReactionAPI) => item.react === reaction);

    if (post_reaction) post_reaction.user_ids?.push(user_id);
    else this.post_reactions?.push({'react' : reaction, 'user_ids' : [user_id]});

    let response = await API.updateReactions(this.id, this.post_reactions);

    return response.status;
  }

  removeReaction = async (user_id : string) => {
    for (var react of this.post_reactions) {
      if (react.user_ids?.includes(user_id)) react.user_ids = react.user_ids.filter((item : string) => item !== user_id );
      if (react.user_ids?.length === 0) u.removeElement(this.post_reactions, react); 
    }

    let response = await API.updateReactions(this.id, this.post_reactions);

    return response.status;
  }
}

export default Post;

