/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import Loader from './primatives/Loader';

import FeaturedProject from './FeaturedProject';
import Project from '../models/Project';
import User from '../models/User';
import { Layout } from '../models/types';

import * as API from '../api';

import '../styles/shared.css';
import '../styles/feeds.css';


interface IProps {
  currentUser? : User;
  layout? : Layout;
  header? : React.ReactNode;
}

interface IState {
  elements : Array<Project>;
  page : number;
}

export default class FeaturedList extends React.Component<IProps, IState> {
  loading : boolean;
  
  public static defaultProps = {
    layout: "vertical",
    hidden : false
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      elements: [],
      page: 0,
    };

    this.loading = false;
  }

  componentDidMount = async () => {
    this.fetchData();
  }

  fetchData = async () => {
    let response = await API.getFeaturedProjects(this.state.page);

    let all_elements : Array<Project> = [];
    if (response.status === 200) {
      let data = await response.json();
      all_elements = data.map((element : any) => { return new Project(element)});
    } 
    this.loading = false;
    this.setState({ elements : [...this.state.elements, ...all_elements], page : this.state.page + 1 });
  }


  render() { 
    if (!this.state.elements) return <div className='project-list-card'/>

    let loader = null;
    if (this.loading) { loader = <Loader/> }

      return (
        <div className={`project-list-card ${this.props.layout}`} 
              id='project-container'>
          {this.state.elements.map(item => {return <FeaturedProject key={item.url} project={item} 
                                                                    currentUser={this.props.currentUser}/>})}
          {loader}
        </div>)
  }
}
