/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import PostElement from '../Post';
import Feed from '../Feed';
import ScrollToTop from '../primatives/ScrollToTop';

import User from '../../models/User';
import Post from '../../models/Post';

import * as API from '../../api';

import '../../styles/shared.css';
import '../../styles/feeds.css';


interface IProps {
  currentUser? : User;
}

interface IState {
  page : number
  posts : Array<Post>;
}

export default class FeedPage extends React.Component<IProps, IState> {
  loading : boolean;
  post_end : boolean;

  constructor(props: IProps) {
    super(props);

    this.state = {
      posts: [], 
      page : 0,
    };
    this.loading = false;
    this.post_end = false;
  }

  componentDidMount = () => {
    document.title = 'Feed - TunePad Community';
  }

  loadMorePosts = async () => {
    if (this.loading || this.post_end) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.getUserFeed(this.state.page);

    if (response.status === 200) { 
      let data = await response.json();
      let all_posts : Array<Post> = data.map((element : any) => { return new Post(element, this.props.currentUser?.uid)});
      if (data.length === 0) this.post_end = true;
      
      this.loading = false;
      this.setState({posts : this.state.posts.concat(all_posts), page : this.state.page + 1});

      return true;
    }
    else {
      this.loading = false;
      this.forceUpdate();

      return false;
    }
  }

  render() { 
    return (
        <div className='content feed'>
          <Feed loadMoreElements={this.loadMorePosts} loading={this.loading} currentUser={this.props.currentUser} 
                elements={this.state.posts.map((item : any) => {
                          return <PostElement key={item.url + Math.random()} post={item}
                                              currentUser={this.props.currentUser}/>})}/>
          <ScrollToTop/>
        </div>)
  }
}