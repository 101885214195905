/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaArrowUp } from 'react-icons/fa';

import '../../styles/shared.css';


export default class ScrollToTop extends React.Component<{}, {}> {
  scrollDisplay : string;

  constructor(props: {}) {
    super(props);
    this.scrollDisplay = 'hidden';
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }
  
  scrollToTop = () => { document.body.scrollTop = document.documentElement.scrollTop = 0; }

  listenToScroll = () => {
    if ((document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) && this.scrollDisplay !== "visible") {
      this.scrollDisplay = "visible";
      this.forceUpdate();
    } else if ( !(document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) && this.scrollDisplay !== "hidden") {
      this.scrollDisplay = "hidden";
      this.forceUpdate();
    }
  }

  render() {
    return (
      <span className={`scroll-top ${this.scrollDisplay}`} onClick={this.scrollToTop}><FaArrowUp/></span>);
    }
  }

  