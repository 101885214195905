/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

// adapted from tutorial by Phil Nash
// https://www.twilio.com/blog/audio-visualisation-web-audio-api--react

import React  from 'react';
import AudioVisualizer from './AudioVisualizer';


interface IProps {
  audioDataBlob: Blob;
  audioElement : HTMLAudioElement;
  color : Array<number>;
}

interface IState {
  audioData : Uint8Array;
}

export default class AudioAnalyser extends React.Component<IProps, IState> {
  audioContext?: AudioContext;
  analyser?: AnalyserNode;
  dataArray?: Uint8Array;
  source?: MediaElementAudioSourceNode;

  rafId?: number;

  constructor(props: IProps) {
    super(props);

    this.state = {
      audioData: new Uint8Array(0)
    };
    
    this.tick = this.tick.bind(this);
  }

  componentDidMount = async () => {
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 64;
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    
    this.source = this.audioContext.createMediaElementSource(this.props.audioElement);

    this.source.connect(this.analyser);
    this.analyser.connect(this.audioContext.destination);
    
    this.rafId = requestAnimationFrame(this.tick);
  }


  tick() {
    this.analyser!.getByteFrequencyData(this.dataArray!);
    this.setState({ audioData: this.dataArray! });
    this.rafId = requestAnimationFrame(this.tick);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rafId!);
    this.analyser!.disconnect();
    this.source!.disconnect();
  }

  render() {
    return <AudioVisualizer audioData={this.state.audioData} bins={this.analyser?.frequencyBinCount ?? 0} color={this.props.color}/>;
  }
}