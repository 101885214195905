/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaUpload } from "react-icons/fa";
import { showPopup } from './Popup';

import * as API from '../../api';
import User from '../../models/User';
import { Size } from '../../models/types';

import '../../styles/shared.css';
import '../../styles/users.css';


interface IProps {
  size : Size;
  editable : boolean;
  photoURI? : string;
  uid? : string;
  user? : User;
}

interface IState {
  imageData? : any;
}

export default class UserPicture extends React.Component<IProps, IState> {
  private readonly inputOpenFileRef : React.RefObject<HTMLInputElement>

  public static defaultProps = {
    size: "large",
    editable : false
  };

  constructor(props : IProps) {
      super(props)

      this.state = {};

      this.inputOpenFileRef = React.createRef()
  }

  componentDidMount = () => {
    if (this.props.photoURI && this.props.uid) this.fetchFile();
  }

  fetchFile = async () => {
    try {
      let response = await API.retrievProfilePicture(this.props.photoURI!, this.props.uid!);
      let data = await response.json();

      this.setState({ imageData : data.img_bytes });
    }
    catch (e) { }
  }

  showOpenFileDlg = () => {
    this.inputOpenFileRef.current!.click()
  }

  previewFile = (file : File) => {
    const reader = new FileReader();
  
    reader.addEventListener("load", () => {
      // convert image file to base64 string
      this.setState({ imageData : reader.result });
    }, false);
  
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  uploadFile = async (file : File) => {
    let result = await this.props.user?.changePicture(file);

    if (result === 200) this.previewFile(file);
    else showPopup(`Error ${result}: Unable to upload file`, 'error', 5000);
  }

  render() {
    let picture = <div className={`default-user-picture profile-picture ${this.props.size}`}/>
    if (this.state.imageData) picture = <img className={`profile-picture ${this.props.size}`} alt='profile' src={this.state.imageData}/>

    if (!this.props.editable) {
      return picture
    } else {
      return (        
        <div className={`profile-picture-wrapper ${this.props.size}`}>
          {picture}
          <input ref={this.inputOpenFileRef} type="file" style={{ display: "none" }} 
                onChange={e => {this.uploadFile(e.target.files![0])}} accept="image/*"/>
          <div className='profile-overlay' onClick={this.showOpenFileDlg} >
            <FaUpload id='upload-icon'/>
          </div>
        </div>)
    }
  }
}