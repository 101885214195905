/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import FeaturedList from '../FeaturedList';
import SearchMenu from '../SearchMenu';
import ProjectElement from '../Project';
import PostElement from '../Post';
import Feed from '../Feed';
import ScrollToTop from '../primatives/ScrollToTop';
import Button from '../primatives/Button';

import User from '../../models/User';
import Project from '../../models/Project';
import Post from '../../models/Post';
import { FeedElement, Sort } from '../../models/types';

import * as API from '../../api';

import '../../styles/shared.css';
import '../../styles/feeds.css';



interface IProps {
  currentUser? : User;
}

interface IState {
  elements : Array<FeedElement>
  tab : string
}

export default class DiscoverPage extends React.Component<IProps, IState> {
  loading : boolean;
  end_posts : boolean;
  end_projects : boolean;
  feed_fetch : Function;
  offset : number;
  sort : Sort;
  query : string;

  constructor(props: IProps) {
    super(props);

    this.state = {
      elements : [],
      tab : 'posts'
    };
    
    this.loading = false;
    this.end_posts = false;
    this.end_projects = false;
    this.feed_fetch = this.loadMorePosts; 
    this.offset = -1; 
    this.query = '';
    this.sort = 'created_desc';
  }

  componentDidMount = () => {
    document.title = 'Discover - TunePad Community';
    const urlParams = new URLSearchParams(window.location.search);
    this.query = urlParams.get('search') ?? '';
    this.offset = 0;
    this.forceUpdate();
  }

  switchTab = (tab : string) => {
    if (tab === 'projects') this.feed_fetch = this.loadMoreProjects;
    else this.feed_fetch = this.loadMorePosts;

    this.offset = 0; this.sort = 'created_desc';
    this.end_posts = this.end_projects = false;
    this.setState({elements : [], tab : tab});
  }

  clearSearch = () => {
    this.query = '';
    window.history.pushState('', "TunePad Fabric", `/discover`);
    this.switchTab(this.state.tab);
  }

  search = (query : string, sort : Sort) => {
    if (this.query !== query && query !== '') {
      window.history.pushState('', "TunePad Community", `/discover?search=${query}`);
    }
    else if (this.query !== query) {
      window.history.pushState('', "TunePad Community", `/discover`);
    }
    
    this.offset = 0;
    this.sort = sort;
    this.query = query;
    this.end_posts = this.end_projects = false;
    this.state.tab === 'posts' ? this.loadMorePosts() : this.loadMoreProjects();
    this.setState({elements : []});
  }

  loadMorePosts = async () => {
    if (this.loading || this.end_posts) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.getAllPosts(this.offset, 25, this.query, this.sort);

    if (response.status === 200) {

        let data = await response.json();
        let all_posts : Array<Post> = data['posts'].map((element : any) => { return new Post(element, this.props.currentUser?.uid)});
        if (data['posts'].length === 0) this.end_posts = true;
        this.loading = false;
        this.offset += 1

        this.setState({elements : this.state.elements.concat(all_posts)});
        
        return true;
    }
    else {
      this.loading = false;
      this.forceUpdate();
      return false;
    }
  }

  loadMoreProjects = async () => {
    if (this.loading || this.end_projects) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.getAllProjects(this.offset, 30, this.query, this.sort)

    if (response.status === 200) {

      let data = await response.json();
      let all_projects : Array<Project> = data.map((element : any) => { return new Project(element)});
      
      if (data.length === 0) this.end_projects = true;
      this.loading = false;
      this.offset += 1
      this.setState({elements : this.state.elements.concat(all_projects)});

      return true;
    }
    else {
      this.loading = false;
      this.forceUpdate();
      return false;
    } 
  }

  render() { 
    // avoid race condition of searching before component is fully mounted
    if (this.offset === -1) { return <div/> }

    let elements;
    if (this.state.tab === 'projects') {
      elements = this.state.elements.map((item : any) => {
        return <ProjectElement key={item.url + Math.random()} project={item}
                               currentUser={this.props.currentUser}/>})
    } else {
      elements = this.state.elements.map((item : any) => {
        return <PostElement key={item.url + Math.random()} post={item}
                               currentUser={this.props.currentUser}/>})
    }

    let searchText = <div/>;
    if (this.query !== '') {
      searchText = (<span className='search-text'>Showing results for "{this.query}" 
                      <Button color='secondary' onClick={this.clearSearch}>CLEAR</Button>
                    </span>);
    }

    return (
        <div id='discover-content'>
          <div id='page-heading' className='section'>
            <span id='page-heading-title'>
              <h1>Featured <span >Projects</span></h1>
            </span>
            <FeaturedList layout='horizontal'/>
          </div>
          <div className='section'>
            <span id='page-heading-title'>
              <h1> <span>Posts</span> & <span >Public Projects</span></h1>
            </span>
          </div>
          <div id='discover-main-content' className='section'>
            <section className='nav-container'> 
              <div className="discover-nav-alignment">
                  <div className={`content-tabs ${this.state.tab}`}>
                    <div id="posts-nav" className="content-nav btn posts" onClick={() => this.switchTab('posts')}>
                      <div className="icon posts-section"></div>
                      <div className="content-nav-label">Posts</div>
                    </div>
                    <div id="projects-nav" className="content-nav btn projects" onClick={() => this.switchTab('projects')}>
                      <div className="icon guidebook-section"></div>
                      <div className="content-nav-label">Projects</div>
                    </div>
                  </div>
                  <SearchMenu searchCallback={this.search} />
                </div>
                <br/>
                <hr/>
              </section>
              {searchText}
              <Feed loadMoreElements={this.feed_fetch} elements={elements} 
                    loading={this.loading} currentUser={this.props.currentUser}/>
              <ScrollToTop/>
          </div>
        </div>)
  }
}