/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

import '../../styles/shared.css';


interface IProps {
  icon? : React.ReactNode;
  text? : string;
}

interface IState {
  visible : boolean;
}


export default class Tooltip extends React.Component<IProps, IState> {
  public static defaultProps = {
    icon: <FaQuestionCircle className='tooltip-icon'/>,
  };

  render() {
    return (
      <>
        <span className='tooltip-outer'>
          {this.props.icon}
          <span className='tooltip-text'>{this.props.text}</span>
        </span>
      </>)
    }
  }

  