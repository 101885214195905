/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import * as API from '../api';
import { Privacy } from './types';


class Project {
  name: string = '';
  description: string = '';
  summary: string = '';
  id: number;
  url: string;

  /// color of the project as a CSS string
  color: string = 'black';

  author?: string;
  authorId?: string;

  artwork?: string; /// artwork URL
  preview?: string; /// audio preview URL

  privacy: Privacy;
  collaborative : boolean;
  readonly: boolean = false;

  /// soft delete
  deleted: boolean = false;

  /// was this project remixed from another project? (null means original)
  remix_parent?: number;

  libraryId: number = -1; /// this is a featured project from the project_library table
  tags: Array<string> = [];
  featured? : boolean;
  long_id : string;

  bpm?: number;
  meter?: string;
  key?: string;
  bars?: number;
  
  created?: Date;

  //liked? : boolean = false; // TODO: parse initial state
  data : any;

  /// other settings and data (using the obsolete pucks field)

  constructor(data: any) {
    this.id = data['project_id'] ?? data['id'];
    this.name = data['name'] ?? 'TunePad Project';

    this.bpm = data['bpm'] ?? 120;
    this.meter = data['meter'] ?? "4/4";
    this.bars = data['bars'] ?? 4;

    this.color = data['color'] ?? '#5bc6fc';
    this.artwork = data['artwork'];

    if (this.artwork?.indexOf('/') === 0) {
      this.artwork = API.HOSTNAME + this.artwork;
    }

    this.preview = data['preview'];

    if (this.preview?.indexOf('/') === 0) {
      this.preview = API.HOSTNAME + this.preview;
    }
    
    this.summary = data['summary'] ?? '';
    this.description = data['description'] ?? '';

    this.privacy = data['approved'] ? 'featured' : (data['visibility'] ?? 'private');
    this.collaborative = data['collaborative'] ?? false;
    this.deleted = data['deleted'] ?? false;
    this.created = new Date(data['created']);

    this.author = data['username'] ?? ''; 
    this.authorId = data['user_id'];

    this.featured = data['approved'];

    this.data = data;

    if (data['pucks']) {
      this.key = data['pucks']['key'] ?? "C major";
      this.readonly = data['pucks']['readonly'] ?? false;
      this.authorId = data['pucks']['owner_id'] ?? "";
    }

    this.long_id = `project/${this.id}`;
    this.url = `${API.LIVEDOMAIN}/project/${this.id}`;
    //this.tags = data['tags']; 

    if (this.author?.includes('@')) {
      this.author = this.author.split('@')[0];
    }
  }

  hide = async () => { 
    let response = await API.editProjectPrivacy(this.id, 'unlisted');

    if (response.status) this.privacy = 'unlisted';

    return response.status;
  }

  unhide = () => { return -1; }

  feature = async () => { 
    let response;
    let data = this.data;
    if (this.featured === undefined) {
      response = await API.featureProject(this.id, 'featured.0');
      data = await response.json();
    }

    // reduce payload and prevent accidental overwriting
    // of the library project artwork and preview
    if('artwork' in this.data){
      delete this.data['artwork'];
    }

    if('preview' in this.data){
      delete this.data['preview'];
    }

    data['approved'] = true;
    data['public'] = true;
    response = await API.updateProject(data['id'], data);

    if (response.status === 200) {
      this.privacy = 'featured';
      this.featured = true;

      this.data = await response.json();
    }

    return response.status;
  }

  unfeature = async () => { 
    this.data['approved'] = false;

    // reduce payload and prevent accidental overwriting
    // of the library project artwork and preview
    if('artwork' in this.data){
      delete this.data['artwork'];
    }

    if('preview' in this.data){
      delete this.data['preview'];
    }

    let response = await API.updateProject(this.data['id'], this.data);

    if (response.status === 200) {
      this.privacy = 'public';
      this.featured = false;
    }

    return response.status;
  }

}


export default Project;

