/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';

import User from '../../models/User';

import '../../styles/shared.css';

interface IProps {
  currentUser? : User;
  show : React.ReactNode;
  className? : string;
  onCancel? : Function;
  visibility? : boolean; // FIXME: this is a dumb way to make the modal hidable by the parent 
}

interface IState {
  visible : boolean;
}


export default class Modal extends React.Component<IProps, IState> {
  wrapperRef : React.RefObject<any>;

  public static defaultProps = {
    className: '',
    visibility : true
  };

  constructor(props : IProps) {
    super(props)
    this.wrapperRef = React.createRef();

    this.state = {
      visible : false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event : MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ visible : false });
    }
  }

  toggleVisible = () => {
    this.setState({ visible : !this.state.visible });
  }

  hideModal = () => {
    if (this.props.onCancel) this.props.onCancel();
    this.setState({ visible : !this.state.visible });
  }

  render() {
    return (
      <>
        <div onClick={this.toggleVisible} className={this.props.className}>{this.props.show}</div>
        <div className='overlay' hidden={!(this.state.visible && this.props.visibility)}>
          <div className='modal' ref={this.wrapperRef}>
            <span className='modal-close' onClick={this.hideModal}>&#x2715;</span>
            {this.props.children}
          </div>
        </div>
      </>)
    }
  }

  