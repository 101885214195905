/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaTrash, FaCheck, FaAngleDown, FaAngleUp, FaSave } from "react-icons/fa";
import moment from 'moment';
import AudioElement from './AudioElement';
import Button from './primatives/Button';
import { showPopup } from './primatives/Popup';

import User from '../models/User';
import LibraryTrack from '../models/LibraryTrack';

import '../styles/shared.css';
import '../styles/admin.css';


interface IProps {
  track : LibraryTrack;
  currentUser? : User;
  adminOptions? : boolean;
}

interface IState {
  expanded : boolean;
  editing : boolean;
}

export default class LibraryTrackElement extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);

    this.state = {
      editing : false,
      expanded : false
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded : !this.state.expanded});
  }

  startEdit = () => {
    if (!this.props.adminOptions) return;
    this.setState({ editing : true });
  }

  saveEdit = async () => {
    if (!this.props.adminOptions) return;
    let status = await this.props.track.saveEdit();

    if (status === 200) {
      this.setState({ editing : false });
      showPopup('Saved track', 'success', 2000);
    } else {
      showPopup('Unable to save track', 'error', 2000);
    }
  }

  deleteTrack = async () => {
    if (!this.props.adminOptions) return;

    if (window.confirm("Are you sure you want to remove this track?")) {
      try {
        await this.props.track.delete();
      } catch (e) {
        console.log(e);
        showPopup('Unable to delete. Check console for error.', 'error', 3000)
      }
    }
    this.forceUpdate();
  }

  approveTrack = async () => {
    if (!this.props.adminOptions) return;

      try {
        await this.props.track.approve();
      } catch (e) {
        console.log(e);
        showPopup('Unable to approve. Check console for error.', 'error', 3000)
      }
    this.forceUpdate();
  }

  render() { 
    if (this.props.track.deleted) return null;

    let actions;
    let new_track;

    if (this.props.track.approved && this.props.adminOptions) {
      actions = <span className='actions'/>;
    } else if (this.props.adminOptions) {
      actions = <span>
                  <span className='actions'>
                    <Button color='confirm' onClick={this.approveTrack}><><FaCheck/> &nbsp;Approve</></Button>
                    <Button color='danger' onClick={this.deleteTrack}><><span className='x-mark'>✖</span> &nbsp;Reject</></Button>
                  </span>
                </span>;
      new_track = <b id='new-label'>[NEW]</b>
    }

      return (
        <>
          <div className={`library-item row`}>
            <span className='name'>
              <div className={`gadget-icon ${this.props.track.instrument}`}>
                <AudioElement audioURL={this.props.track.audio_url} visualize={false}/>
              </div>
              {new_track} &nbsp;{this.props.track.name}
            </span>
            <span className='author'><a href={`${window.location.origin}/user/${this.props.track.author_id}`} target="_blank" rel="noreferrer">{this.props.track.author}</a></span>
            {actions}
            <span className='expand' onClick={this.toggleExpanded}>
              {this.state.expanded ? <FaAngleUp/> : <FaAngleDown/>}
              </span>
          </div>
          <div className={`details ${this.state.expanded ? 'expanded' : ''}`}>
              <span className='row'>
                <b>{this.props.track.fave_count} likes</b>
                    <div className='actions'>
                      <Button color='confirm' disabled={!this.state.editing} onClick={this.saveEdit}><><FaSave/> &nbsp;Save</></Button>
                      {this.props.track.approved ? <Button color='danger' onClick={this.deleteTrack}><><FaTrash/> &nbsp;Remove</></Button> : null}
                    </div>

              </span>
              <span className='row'>
                <input id="name" name="name" defaultValue={this.props.track.name} readOnly={!this.props.adminOptions}
                          onChange={e => {  this.props.track.name = e.target.value; this.setState({ editing : true }); }}/>
              </span>
              <span className='row'>
                <textarea defaultValue={this.props.track.description} placeholder={'no description'} readOnly={!this.props.adminOptions}
                          onChange={e => { this.props.track.description = e.target.value; this.setState({ editing : true }); }}/>
              </span>
              <span className='row code'>
                <pre>{this.props.track.code}</pre>
              </span>
              <span className='row'>
                <span>
                  <b>Created:</b> {moment(this.props.track.creation_date).format('MMMM Do YYYY, h:mm:ss a')}</span>
                </span>
          </div>
        </>)
  }
}