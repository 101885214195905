/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

// adapted from tutorial by Phil Nash
// https://www.twilio.com/blog/audio-visualisation-web-audio-api--react

import React  from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import AudioAnalyser from './AudioAnalyser';
import { showPopup } from './primatives/Popup';

import * as API from '../api';


interface IProps {
  visualize : boolean;
  audioURL : string;
  color? : Array<number>;
}

interface IState {
  playing : boolean;
}

export default class AudioElement extends React.Component<IProps, IState> {
  audio : HTMLAudioElement;
  audioData? : Blob;

  public static defaultProps = {
    color: [95, 5, 51],
    visualize: false
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      playing : false
    };

    this.audio = new Audio();

    this.audio.preload = 'none';
    this.audio.loop = false;
    this.audio.className = 'post-audio';

    this.audio.onpause = () => { 
      this.audio.currentTime = 0; 
      this.setState({ playing : false }); 
    };

    this.audio.onplay = async () => {
      try {
        if (!this.audioData) {
          this.audioData = await this.fetchAudioData();
          this.forceUpdate();
        }
        if (!this.audio.src) this.audio.src = URL.createObjectURL(this.audioData); 
      } catch (e) {
        console.debug(e);
        showPopup('Unable to play post audio.', 'error', 3000)
      }
    }

    this.audio.onended = () => {
      this.audio.currentTime = 0; 
      this.setState({ playing : false }); 
    }

    document.getElementById('root')?.appendChild(this.audio);
    
  }

  componentDidMount = async () => {
  }

  togglePlaying = async () => {
    document.querySelectorAll('audio').forEach(el => el.pause());

    if (!this.state.playing) {
      const playPromise = this.audio.play();

      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            this.setState({playing : !this.state.playing});
          })
          .catch(_ => {
            console.debug('playback prevented');
          });
      }
    } else {
      this.audio.pause();
      this.setState({playing : !this.state.playing});
    }
  }

  fetchAudioData = async () => {
    if (!this.props.audioURL) return new Blob();
    let response = await API.getAudioData( this.props.audioURL );
    return await response.blob();
  }

  render() {
    let audioIcon = <FaPlay className='' onClick={this.togglePlaying}/>;
    if (this.state.playing === true) audioIcon = <FaPause onClick={this.togglePlaying}/>

    return (
    <div className={this.state.playing ? 'playing ' : ''}>
      <span className='play-icon'>{audioIcon}</span> 
      {(this.props.visualize && this.audioData) ? 
        <AudioAnalyser audioDataBlob={this.audioData} audioElement={this.audio} color={this.props.color!}/> : ''}
    </div>);
  }
}