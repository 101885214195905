/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import AudioElement from './AudioElement';
import Button from './primatives/Button';
import { showPopup } from './primatives/Popup';

import Project from '../models/Project';
import User from '../models/User';
import { ElementLayout, Size } from '../models/types';

import '../styles/shared.css';
import '../styles/projects.css';



interface IProps {
  project : Project;
  currentUser? : User;
  size? : Size;
  layout? : ElementLayout;
}
interface IState { }

export default class ProjectElement extends React.Component<IProps, IState> {
  public static defaultProps = {
    size : "small",
    layout: "card"
  };

  featureProject = async () => {
    let status = await this.props.project.feature();

    if (status === 200) showPopup(`Project featured successfully!`, 'success', 5000);
    else showPopup(`Unable to feature project. Check console for more info.`, 'error', 5000);

    this.forceUpdate();
  }

  unfeatureProject = async () => {
    let status = await this.props.project.unfeature();

    if (status === 200) showPopup(`Project unfeatured successfully!`, 'success', 5000);
    else showPopup(`Unable to unfeature project. Check console for more info.`, 'error', 5000);

    this.forceUpdate();
  }

  hideProject = async () => {
    let status = await this.props.project.hide();

    if (status === 200) showPopup(`Project hidden successfully!`, 'success', 5000);
    else showPopup(`Unable to hide project. Check console for more info.`, 'error', 5000);

    this.forceUpdate();

  }

  render() { 
    let project_sharing_badge;

    if (this.props.project.privacy === 'private') {
      project_sharing_badge = <div className='project-sharing private'>PRIVATE</div>
    } else if (this.props.project.privacy === 'unlisted' && this.props.project.collaborative) {
      project_sharing_badge = <div className='project-sharing collaborative'>COLLAB</div>
    } else if (this.props.project.privacy === 'unlisted') {
      project_sharing_badge = <div className='project-sharing viewonly'>UNLISTED</div>
    } else if (this.props.project.privacy === 'featured') {
      project_sharing_badge = <div className='project-sharing featured'>FEATURED</div>
    } else {
      project_sharing_badge = <div className='project-sharing public'>PUBLIC</div>
    }

    let audioIcon = <div/>
    if (this.props.project.preview) {
      audioIcon = <AudioElement audioURL={this.props.project.preview} visualize={false}/>
    }

    /*let tags = <div/>
    if (this.props.project.tags && this.props.project.tags.length > 0) {
      tags = <ul className="tags">{this.props.project.tags.map((t : any) => {return <li key={t}>#{t}</li>})}</ul>
    }*/

    let artwork = <div className="project-icon" style={{backgroundColor : this.props.project.color}}>{audioIcon}</div>;
    if (this.props.project.artwork) {
      artwork = <div className="project-icon-art"><img src={this.props.project.artwork} alt='project art'/>{audioIcon}</div>
    }

    let overflowClass = ''
    if (this.props.project.name.length > 22) {
      overflowClass = 'overflowing'
    }

    let featuring = <Button onClick={this.featureProject} disabled={!this.props.project.preview}>Feature</Button>
    if (this.props.project.featured) featuring = <Button color="danger" onClick={this.unfeatureProject}>Unfeature</Button>

    if (this.props.layout === "table") {
      return (
        <div className='project-item table row'>
          <span className='name'>
          {artwork}
          {project_sharing_badge}
            <a href={this.props.project.url} target="_blank" rel="noreferrer" className='project-name-link'>
              <span className={`project-name-text ${overflowClass}`}>{this.props.project.name}</span>
            </a>
          </span>
          <span className='author'><a href={`/user/${this.props.project.authorId}`}>{this.props.project.author}</a></span>
          <span className='actions'>
            <Button color="secondary">
              <><a href={this.props.project.url} target="_blank" rel="noreferrer">
              <FaExternalLinkAlt/> &nbsp;Open</a></>
            </Button>
            {featuring}
            {!this.props.project.featured ? <Button color='danger-secondary' onClick={this.hideProject}>Hide</Button> : null}
          </span>
          </div>
      );
    }
    else {
      return (
        <div className={`project-item card ${this.props.size}`}>
          <div className="project-summary">
            {artwork}
            <div className="project-name"><a href={this.props.project.url} target="_blank" rel="noreferrer" className='project-name-link'><span className={`project-name-text ${overflowClass}`}>{this.props.project.name}</span></a>
              <div className="author">
                by <a href={`/user/${this.props.project.authorId}`}>{this.props.project.author}</a></div>
              </div>
            {project_sharing_badge}
          </div>

          <div className="project-details">
            <div className="project-badge">
              <div className="left-column">
                <div className="artwork" style={{backgroundColor : this.props.project.color}}></div>
                <div className="music-info">{this.props.project.bpm}bpm | {this.props.project.meter} | {this.props.project.key}</div>
                <div className="creation-date">Created: {this.props.project.created?.toDateString()}</div>
              </div>

              <div className="project-toolbar">
                <Button color='secondary'>
                  <><a href={this.props.project.url} target="_blank" rel="noreferrer">
                  <FaExternalLinkAlt/> Open</a></>
                </Button>
            </div>
          </div>
      </div>
    </div>);
    }
  }
}