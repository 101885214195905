/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from "react";

import Feed from "./Feed";
import PostReportElement from "./PostReport";
import ErrorMessage from "./primatives/ErrorMessage";

import User from "../models/User";
import PostReport from "../models/PostReport";
import { FeedElement } from "../models/types";

import * as API from '../api';

import "../styles/shared.css";
import "../styles/admin.css";


interface IProps {
  currentUser? : User;
}

interface IState {
  error : boolean;
  elements : Array<FeedElement>
}

export default class ReportTable extends React.Component<IProps, IState> {
  feed_end : boolean;
  feed_loading : boolean;
  feed_page : number;

  constructor(props : IProps) {
      super(props)

      this.state = {
        error : false,
        elements : []
      };

      this.feed_end = false;
      this.feed_loading = false;
      this.feed_page = 0;
  }

  fetchReports = async () => {
    if (this.feed_loading || this.feed_end) return;

    this.feed_loading = true;
    this.forceUpdate();

    let response = await API.getFlaggedPosts(this.feed_page); 
    let data = await response.json();
    if (data.length === 0) this.feed_end = true;
    let new_reports = data.map((element : any) => { return new PostReport(element) });

    this.feed_page += 1;
    this.feed_loading = false;

    this.setState({ elements : [...this.state.elements, ...new_reports]});
  }

  refreshQueue = async () => {
    this.feed_page = 0;
    this.feed_end = false;
    this.setState({elements : []}, () => { this.fetchReports(); });
  }

  render() { 
    if (this.state.error) return (<ErrorMessage/>);

    let header =           
      <div id='table-header'  className='row' key='head'>
        <span className='author'>Original Poster</span>
        <span className='author'>Reported by</span>
        <span className='reason'>Reason</span>
        <span className='actions'> </span>
        <span className="expand"> </span>
      </div>

    return (
        <Feed additionalClasses='admin-table-card table library' currentUser={this.props.currentUser} loading={this.feed_loading} 
              loadMoreElements={this.fetchReports} header={header} elements={this.state.elements.map((item : any) => {
                return <PostReportElement key={item.post_id + Math.random()} report={item} currentUser={this.props.currentUser} refresh={this.refreshQueue}/>})}/>
      )
  }
}