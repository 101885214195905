/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import React from 'react';
import TunePadHeader from './components/TunePadHeader';
import ProfilePage from './components/pages/ProfilePage'

import * as API from './api';
import User from './models/User';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import FeedPage from './components/pages/FeedPage';
import DiscoverPage from './components/pages/DiscoverPage';
import ErrorMessage from './components/primatives/ErrorMessage';
import SinglePostPage from './components/pages/SinglePostPage';
import AdminPage from './components/pages/AdminPage';

import './styles/shared.css';


interface IState {
  currentUser? : User;
}

export default class App extends React.Component<{}, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentUser: undefined
    };
  }

  componentDidMount = async () => {

    let response = await API.connect();

    if (response.status === 200) {
      let data = await response.json()
      if (typeof data === "string") data = JSON.parse(data);
      if (data.success === false) {
        const redirectUrl = window.location.href.replace(/\/$/, "");
        window.location.href = `${API.LIVEDOMAIN}/login?redirect=${redirectUrl}`;
        return;
      }

      let u = new User(data, true);
      await u.init();
      this.setState({ currentUser : u });
    }
    else {
      console.log("failed to connect");
      const redirectUrl = window.location.href.replace(/\/$/, "");
      window.location.href = `${API.LIVEDOMAIN}/login?redirect=${redirectUrl}`;
      this.setState({ currentUser : undefined});
      return;
    }
  }

  render() {
    if (this.state.currentUser === undefined && window.location.pathname !== '/') return <div/>
    return (
      <>
        <TunePadHeader currentUser={this.state.currentUser}/>
        <span id='message-root'/>
        <Router>
          <Switch>
            {<Route exact path="/" render={props => {
              window.location.href = `/feed`;
              return null;
            }} />}
            {/*<Route exact path="/" render={routeProps => (<HomePage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />*/}
            <Route exact path="/feed" render={routeProps => (<FeedPage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route exact path="/discover" render={routeProps => (<DiscoverPage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route exact path="/profile" render={routeProps => (<ProfilePage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route exact path="/admin" render={routeProps => (<AdminPage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route       path="/user" render={routeProps => (<ProfilePage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route       path="/post" render={routeProps => (<SinglePostPage {...routeProps}
                                                                    currentUser={this.state.currentUser}/>)} 
                                                          key={window.location.pathname} />
            <Route path="*"><ErrorMessage /></Route>

          </Switch>
      </Router>
    </>
    );
  }
}
